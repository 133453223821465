import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./IntakeForms.scss";

import { Button, List, Modal } from "antd";
import IntakeFormCard from "./components/IntakeFormCard";
import IntakeFormModal from "./components/IntakeFormModal";
import { intakeFormEmailReminder } from "services/intakeForm.service";
import HistoryIntakeForm from "components/HistoryIntakeForm/HistoryIntakeForm";
import ConsentForm from "components/ConsentForm/ConsentForm";
import { FormType } from "utils/enums";
import { get } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { reopenForm } from "services/forms.service";

const FORMS = [
  {
    type: FormType.historyOrPhysical,
    title: "History/Physical",
  },
  {
    type: FormType.consent,
    title: "Consents",
  },
  {
    type: FormType.goodFaithExam,
    title: "Good Faith Exam",
  },
];

function IntakeForms({ variant = "full", user, refreshCustomer, orderId }) {
  const [showHistoryForm, setShowHistoryForm] = useState({
    visible: false,
    isEdit: false,
  });
  const [showConsentForm, setShowConsentForm] = useState({
    visible: false,
    isEdit: false,
  });
  const [showGoodFaithExam, setshowGoodFaithExam] = useState({
    visible: false,
    isEdit: false,
  });
  const dispatch = useDispatch();

  const getModalVisibilityHandler = (type) => {
    switch (type) {
      case FormType.historyOrPhysical:
        return setShowHistoryForm;
      case FormType.consent:
        return setShowConsentForm;
      case FormType.goodFaithExam:
        return setshowGoodFaithExam;
      default:
        break;
    }
  };

  const getIsEditAllowed = (type) => {
    switch (type) {
      case FormType.goodFaithExam:
        if (user?.totalOrdersCount >= 1) {
          return true;
        }
        return false;
      default:
        return true;
    }
  };

  const {
    customerId,
    isPhysicalHistoryExpired,
    isConsentExpired,
    isIntakeFormSubmitted,
    isConsentFormSubmitted,
    legacyConsentFormId,
    legacyIntakeFormId,
    isConsentDesignOutdated,
    isPhysicalHistoryDesignOutdated,
    consentFormId,
    intakeFormId,
    legacyPhysicalHistorySubmittedOn,
    physicalHistorySubmittedOn,
    consentSubmittedOn,
    legacyConsentSubmittedOn,

    goodFaithFormId,
    goodFaithSubmittedOn,
    isGoodFaithExpired,
    isGoodFaithOutdated, // no in backend yet
    isGoodFaithSubmitted,
  } = user;
  const isConsentFormLegacy =
    legacyConsentFormId && !consentFormId ? true : false;
  const intakeFormLegacy = legacyIntakeFormId && !intakeFormId ? true : false;

  function getConsentDate(intake) {
    const consentSubmittedOnDate = isConsentFormLegacy
      ? legacyConsentSubmittedOn
      : consentSubmittedOn;
    const historyPhysicalSubmittedOn = intakeFormLegacy
      ? legacyPhysicalHistorySubmittedOn
      : physicalHistorySubmittedOn;

    if (intake === FormType.historyOrPhysical) {
      return historyPhysicalSubmittedOn;
    } else if (intake === FormType.consent) {
      return consentSubmittedOnDate;
    } else if (intake === FormType.goodFaithExam) {
      return goodFaithSubmittedOn;
    }
    return "";
  }

  function getIsFilled(intake) {
    if (intake === FormType.historyOrPhysical) {
      return isIntakeFormSubmitted;
    } else if (intake === FormType.consent) {
      return isConsentFormSubmitted;
    } else if (intake === FormType.goodFaithExam) {
      return isGoodFaithSubmitted === true;
    }
  }

  function getIsReopened(intake) {
    if (intake === FormType.goodFaithExam) {
      return isGoodFaithSubmitted === false;
    }
  }

  function getIsExpired(intake) {
    if (intake === FormType.historyOrPhysical) {
      return isPhysicalHistoryExpired;
    } else if (intake === FormType.consent) {
      return isConsentExpired;
    } else if (intake === FormType.goodFaithExam) {
      return isGoodFaithExpired;
    }
  }

  function getIsLegacy(intake) {
    if (intake === FormType.historyOrPhysical) {
      return intakeFormLegacy;
    } else if (intake === FormType.consent) {
      return isConsentFormLegacy;
    } else {
      return false; // there is no legacy good faith exam
    }
  }

  function getIsOutdated(intake) {
    if (intake === FormType.historyOrPhysical) {
      return isPhysicalHistoryDesignOutdated;
    } else if (intake === FormType.consent) {
      return isConsentDesignOutdated;
    } else if (intake === FormType.goodFaithExam) {
      return isGoodFaithOutdated;
    }
  }

  return (
    <>
      <List
        className={`intakeFormsList`}
        itemLayout="vertical"
        size="large"
        dataSource={FORMS}
        renderItem={(intakeForm, index) => (
          <List.Item key={index}>
            <IntakeFormCard
              user={user}
              key={index}
              type={intakeForm.type}
              title={intakeForm.title}
              date={getConsentDate(intakeForm.type)}
              isFilled={getIsFilled(intakeForm.type)}
              isExpired={getIsExpired(intakeForm.type)}
              isLegacy={getIsLegacy(intakeForm.type)}
              isOutdated={getIsOutdated(intakeForm.type)}
              modalVisibilityHander={getModalVisibilityHandler(intakeForm.type)}
              isAllowedToEdit={getIsEditAllowed(intakeForm.type)}
              refreshCustomer={refreshCustomer}
              handleGoodFaithReopen={()=>{
                Modal.confirm({
                  content: "Are you sure you want to reopen this form?",
                  title: "Reopen Good Faith Form",
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => {
                    dispatch(reopenForm(goodFaithFormId, customerId)).then(
                      (res) => {
                        if (res?.status === "success") {
                          refreshCustomer();
                        }
                      }
                    );
                  },
                  maskClosable: true,
                })
              }}
            />
          </List.Item>
        )}
      />
      <div className="intakeFormReminderSection">
        <span
          style={{ fontFamily: "tradeGothic ", fontSize: 14, fontWeight: 400 }}
        >
          Remind user of submitting intake forms?
        </span>
        <Button
          type="text"
          className="reminderBtn"
          onClick={() => {
            dispatch(intakeFormEmailReminder(user?.customerId));
          }}
        >
          Send Reminder
        </Button>
      </div>

      <IntakeFormModal
        visible={showGoodFaithExam.visible}
        isEdit={showGoodFaithExam.isEdit}
        title="Good Faith Exam"
        type={FormType.goodFaithExam}
        onSubmit={() => {
          setshowGoodFaithExam({
            visible: false,
            isEdit: false,
          });
          refreshCustomer();
        }}
        onClose={() =>
          setshowGoodFaithExam({
            visible: false,
            isEdit: false,
          })
        }
        refreshData={refreshCustomer}
        isSubmitted={getIsFilled(FormType.goodFaithExam)}
        formId={goodFaithFormId}
        customerId={user?.customerId}
        formCode={4}
        orderId={orderId}
        className={`goodfaithexam-${user?.customerId}-${orderId}`}
      />
      <IntakeFormModal
        visible={
          showHistoryForm.visible &&
          (!intakeFormLegacy || showHistoryForm.isEdit)
        }
        isEdit={showHistoryForm.isEdit}
        title="History/Physical and Consents"
        type={FormType.historyOrPhysical}
        onSubmit={() => {
          setShowHistoryForm({
            visible: false,
            isEdit: false,
          });
          refreshCustomer();
        }}
        onClose={() =>
          setShowHistoryForm({
            visible: false,
            isEdit: false,
          })
        }
        isSubmitted={getIsFilled(FormType.historyOrPhysical)}
        formId={user?.intakeFormId}
        customerId={user?.customerId}
        formCode={2}
        orderId={orderId}
        className={`history-${user?.customerId}-${orderId}`}
      />
      <IntakeFormModal
        visible={
          showConsentForm.visible &&
          (!isConsentFormLegacy || showConsentForm.isEdit)
        }
        isEdit={showConsentForm.isEdit}
        type={FormType.consent}
        onSubmit={() => {
          setShowConsentForm({
            visible: false,
            isEdit: false,
          });
          refreshCustomer();
        }}
        onClose={() =>
          setShowConsentForm({
            visible: false,
            isEdit: false,
          })
        }
        title="Consents"
        formId={user?.consentFormId}
        isSubmitted={getIsFilled(FormType.consent)}
        customerId={user?.customerId}
        formCode={3}
        orderId={orderId}
        className={`consent-${user?.customerId}-${orderId}`}
      />
      <HistoryIntakeForm
        visible={
          showHistoryForm.visible && intakeFormLegacy && !showHistoryForm.isEdit
        }
        onSubmit={() => {
          setShowHistoryForm({
            visible: false,
            isEdit: false,
          });
        }}
        onClose={() =>
          setShowHistoryForm({
            visible: false,
            isEdit: false,
          })
        }
        user={user}
      />
      <ConsentForm
        visible={
          showConsentForm.visible &&
          isConsentFormLegacy &&
          !showConsentForm.isEdit
        }
        onSubmit={() =>
          setShowConsentForm({
            visible: false,
            isEdit: false,
          })
        }
        onClose={() =>
          setShowConsentForm({
            visible: false,
            isEdit: false,
          })
        }
        user={user}
      />
    </>
  );
}

IntakeForms.propTypes = {
  variant: "full" | "minimal",
};

export default IntakeForms;
