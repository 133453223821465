import React, { useEffect, useRef } from "react";
import { Col, Drawer, Form, Row, Button as AntdButton, Grid } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import ShiftItem from "components/ShiftItem/ShiftItem";
import { getShiftById } from "services/shifts.service";
import moment from "moment";
const { useBreakpoint } = Grid;
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function ShiftDrawer({
  data,
  visible,
  onClose,
  onSuccess,
  facilities,
  isLoadingFacilities,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const dispatch = useDispatch();
  const isEdit = data?.shiftId;

  const facilityId = Form.useWatch("facilityId", form);
  useEffect(() => {
    if (visible && data?.shiftId) {
      dispatch(getShiftById(data?.facilityId, data?.shiftId)).then((res) => {
        if (res?.status === "success") {
          // const sortDays = function (a, b) {
          //   a = days?.indexOf(a?.day?.toLowerCase?.());
          //   b = days?.indexOf(b?.day?.toLowerCase?.());
          //   if (a < b) return -1;
          //   else if (a > b) return 1;
          //   else return 0;
          // };
          // res?.data?.sort(sortDays);

          let facilityId = undefined;
          let operationTypeId = undefined;
          let shiftName = undefined;
          let startTime = undefined;
          let endTime = undefined;
          const facilityShiftDetails = [];

          for (let i = 0; i < days.length; i++) {
            const day = days[i];
            const shift = res?.data?.find((it) => it?.day === day);

            if (shift) {
              facilityId = shift?.facilityId;
              operationTypeId = shift?.operationTypeId;
              shiftName = shift?.shiftName;
              startTime = shift?.startTime
                ? moment(shift?.startTime, "HH:mm")
                : undefined;
              endTime = shift?.endTime
                ? moment(shift?.endTime, "HH:mm")
                : undefined;
              facilityShiftDetails.push({
                facilityShiftId: shift?.facilityShiftId,
                day: shift?.day,
                shiftId: shift?.shiftId,
                maximumShiftsAllowed: shift?.maximumShiftsAllowed,
              });
            } else {
              facilityShiftDetails.push({
                day: day,
              });
            }
          }

          form.setFieldsValue({
            facilityId,
            operationTypeId,
            shiftName,
            shiftDetail: [
              {
                shiftName,
                startTime,
                endTime,
                facilityShiftDetails: facilityShiftDetails,
              },
            ],
          });
        }
      });
    } else {
      form.resetFields();
    }
  }, [visible]);

  const availableOperationTypes = facilities?.find?.(
    (it) => it?.facilityId === facilityId
  )?.operationTypeIds;

  const generateSubmittableValues = (values) => {
    const { facilityId, operationTypeId, shiftDetail } = values;
    let submittableValues = { facilityShiftDetails: [] };
    for (let i = 0; i < shiftDetail.length; i++) {
      const { startTime, endTime, facilityShiftDetails, shiftName } =
        shiftDetail[i];
      submittableValues.startTimeString = startTime?.format?.("HH:mm");
      submittableValues.endTimeString = endTime?.format?.("HH:mm");
      submittableValues.shiftName = shiftName;
      submittableValues.facilityId = facilityId;
      submittableValues.operationTypeId = operationTypeId;
      for (let j = 0; j < facilityShiftDetails.length; j++) {
        console.log("facilityShiftDetails", facilityShiftDetails);
        const { day, maximumShiftsAllowed } = facilityShiftDetails[j];
        if (
          maximumShiftsAllowed !== undefined &&
          maximumShiftsAllowed !== null
        ) {
          submittableValues.facilityShiftDetails.push({
            days: day,
            maximumShiftsAllowed,
          });
        }
      }
    }
    return submittableValues;
  };

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="categoriesDrawer"
      width={"min(100vw, 520px)"}
      title={
        <div className="categoriesDrawerTitle">
          <p>
            {visible && (data?.shiftId !== undefined ? "Edit" : "Create")} Shift
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="categoriesDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          //generateSubmittableValues(values);
          onSuccess(generateSubmittableValues(values));
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <FormSelect
          // mode={"multiple"}
          required
          feedBack={false}
          variant="underlined"
          name="facilityId"
          loading={isLoadingFacilities}
          label="Facilities"
          renderValue="facilityId"
          placeholder={"Select Facilities"}
          renderLabel="facilityName"
          styles={{ border: "none" }}
          // formItemStyles={{ marginBottom: 12 }}
          options={facilities}
          optionLabelProp="label"
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          onChange={() => {
            form.setFieldValue("operationTypeId", undefined);
          }}
          disabled={isEdit}
          // styles={{ background: '#ddd' }}
        />
        <FormSelect
          required
          variant="underlined"
          renderLabel="operationTypeName"
          renderValue="operationTypeId"
          name="operationTypeId"
          label="Operation Type"
          placeholder={"Select"}
          styles={{ border: "none" }}
          disabled={isEdit}
          options={[
            {
              operationTypeId: 2,
              operationTypeName: "Clinic",
            },
            {
              operationTypeId: 1,
              operationTypeName: "Mobile",
            },
            {
              operationTypeId: 3,
              operationTypeName: "Telehealth",
            },
          ]?.filter?.((option) =>
            availableOperationTypes?.includes?.(option?.operationTypeId)
          )}
          // styles={{ background: '#ddd' }}
        />
        <ShiftItem index={0} isSmallDevice={isSmallDevice} form={form} />
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Drawer>
  );
}

ShiftDrawer.propTypes = {};

export default ShiftDrawer;
