export const OPERATION_TYPE = {
  Clinic: 2,
  Mobile: 1,
};

export const ORDER_STATUS_MAPPING = {
  1: {
    text: "Pending",
    icon: <></>,
    color: "",
  },
  2: {
    text: "Cancelled",
    icon: <></>,
    color: "",
  },
  3: {
    text: "Completed",
    icon: <></>,
    color: "",
  },
  4: {
    text: "No Show",
    icon: <></>,
    color: "",
  },
  5: {
    text: "Arrived",
    icon: <></>,
    color: "",
  },
};

export const JOUNRAL_ENTRY_STATUS = {
  1: "New",
  2: "Reviewed",
  3: "Failed",
  4: "Posted",
};

export const PROGRAM_TYPE = {
  Program: 1,
  Membership: 2,
  "Voucher Code": 3,
  Referral: 5,
  "Promotional Gift Card": 4,
  // "Gift Card": 5,
};

export const BENEFIT_TYPE = {
  "Discount Amount": 1,
  "Discount Percentage": 2,
  "Bonus Service": 3,
  REFERAL: 4,
};

export const PRODUCT_TYPE = {
  Service: 1,
  Boost: 2,
  Telehealth: 3,
  Program: 4,
};

export const EXCLUSION_TYPE = {
  "All Included": 1,
  "Only Included": 2,
  "All Include Except": 3,
};

export const getEnumKeyByValue = (enumObj, value) => {
  for (let [enumKey, enumValue] of Object.entries(enumObj)) {
    if (enumValue === value) return enumKey;
  }
  return;
};

export const RestrictionType = {
  1: "Product to Product", // no time interval and age
  2: "Interval", // no age
  3: "Age", // no time interval and no restricted product id
};

export const ProductType = {
  Service: 1,
  Boost: 2,
  Telehealth: 3,
};

export const CalenderOperationType = {
  All: 0,
  Mobile: 1,
  Clinic: 2,
  Telehealth: 3,
};

export const FormType = {
  chart: 1,
  consent: 3,
  historyOrPhysical: 2,
  goodFaithExam: 4,
};

export const Months = {
  1: {
    shortName: "Jan",
    fullName: "January",
  },
  2: {
    shortName: "Feb",
    fullName: "February",
  },
  3: {
    shortName: "Mar",
    fullName: "March",
  },
  4: {
    shortName: "Apr",
    fullName: "April",
  },
  5: {
    shortName: "May",
    fullName: "May",
  },
  6: {
    shortName: "June",
    fullName: "June",
  },
  7: {
    shortName: "July",
    fullName: "July",
  },
  8: {
    shortName: "August",
    fullName: "August",
  },
  9: {
    shortName: "September",
    fullName: "September",
  },
  10: {
    shortName: "October",
    fullName: "October",
  },
  11: {
    shortName: "November",
    fullName: "November",
  },
  12: {
    shortName: "December",
    fullName: "December",
  },
};

export const RosterFilterType = {
  All: 0,
  Daily: 1,
  Monthly: 2,
  Weekly: 3,
};
export const BILLING_FREQUENCY = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Yearly: 4,
};

export const PAYMENT_METHOD_TYPE = {
  Stripe: 1,
  Cash: 2,
  Wallet: 3,
};

export const MeetingVendor = {
  Zoom: 1,
  Google: 2,
};
