import {
  Space,
  Button as AntdButton,
  Typography,
  Divider,
  Empty,
  Radio,
  Modal as AntdModal,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BENEFIT_TYPE } from "utils/enums";
import moment from "moment";

import styles from "./styles.module.scss";

const { Text, Title } = Typography;

export default function WalletSelectPopup({
  visible,
  onCancel,
  onSuccess,
  wallets,
  isCustomerWalletsLoading,
  applicableWallets,
}) {
  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    if (visible === false) {
      setSelectedWallet(null);
    }
  }, [visible]);

  const availableWallets = useMemo(() => {
    console.log("wallets", wallets);
    console.log("applicableWallets", applicableWallets);
    return wallets.filter((wallet) => {
      if (wallet.isGeneral == true) {
        return true;
      } else if (
        applicableWallets.find((promoWallet) => promoWallet.walletId === wallet.walletId)
      ) {
        console.log('applicableWalletFound', wallet.walletId)
        return true;
      }
      return false;
    });
  }, [wallets, applicableWallets]);

  console.log("availableWallets", availableWallets);

  return (
    <Modal
      destroyOnClose={true}
      className=""
      title="Wallets List"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save"
      onOk={() => {
        if (selectedWallet) {
          onSuccess(selectedWallet);
        } else {
          AntdModal.error({
            title: "Please select a wallet",
            maskClosable: true,
            content: "No wallet selected. Please select a wallet to proceed.",
          });
        }
      }}
      //   okButtonProps={{
      //     disabled: !initialValue || initialValue?.length <= 0,
      //   }}
    >
      <div>Available Wallets</div>

      <Radio.Group
        className={styles.checkboxContainer}
        onChange={(e) => {
          console.log("e", e.target.value);
          setSelectedWallet(e.target.value);
        }}
        value={selectedWallet}
      >
        {availableWallets.map((wallet) => {
          return (
            <Radio
              key={wallet.walletId}
              value={wallet.walletId}
              className={styles.checkbox}
            >
              <span>{wallet?.isGeneral ? "General" : wallet?.programName}</span>
              &nbsp;
              <span>(${Math.abs(wallet?.balance ?? 0).toFixed(2)})</span>
            </Radio>
          );
        })}
      </Radio.Group>
      <div></div>
    </Modal>
  );
}
