import React from "react";

import LocationSelector from "components/LocationSelector/LocationSelector";
import DayWeekSelector from "./DayWeekSelector";
import ViewSelector from "./ViewSelector";

import { Button } from "components/Buttons/Buttons";

import { Grid, Space } from "antd";
import OperationTypeSelector from "components/OperationTypeSelector/OperationTypeSelector";
import { CalenderOperationType } from "utils/enums";
import NurseSelector from "components/NurseSelector/NurseSelector";
import CalendarFilters from "components/Calendar/components/CalendarFilters";

const { useBreakpoint } = Grid;

const Toolbar = (params) => {
  const {
    date,
    view,
    onView,
    onNavigate,
    bookAnAppointmentClick,
    facilities,
    setFacility,
    currentFacilityIds,
    isFetchingFacilities,
    operationType,
    setOperationType,
    showFilter,
    setShowFilter,
  } = params;

  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const renderControls = () => {
    if (!isSmallDevice) {
      return (
        <>
          <DayWeekSelector date={date} onNavigate={onNavigate} />

          <Space size={16} className="toolbarSizer">
            {(operationType === CalenderOperationType.All ||
              operationType === CalenderOperationType.Telehealth) && (
              <CalendarFilters
                setShowFilter={setShowFilter}
                showFilter={showFilter}
              />
            )}
            <ViewSelector
              view={view}
              onView={onView}
              views={
                operationType === CalenderOperationType.Telehealth
                  ? ["agenda"]
                  : ["day", "agenda"]
              }
            />
          </Space>
        </>
      );
    } else {
      return <div style={{ display: "flex" }}>
        <DayWeekSelector date={date} onNavigate={onNavigate} />

        <Space size={16} className="toolbarSizer">
          {(operationType === CalenderOperationType.All ||
            operationType === CalenderOperationType.Telehealth) && (
            <CalendarFilters
              setShowFilter={setShowFilter}
              showFilter={showFilter}
            />
          )}
          <ViewSelector
            view={view}
            onView={onView}
            views={
              operationType === CalenderOperationType.Telehealth
                ? ["agenda"]
                : ["day", "agenda"]
            }
          />
        </Space>
      </div>;
    }
  };

  return (
    <div className="toolbar">
      <OperationTypeSelector
        value={operationType}
        onChange={setOperationType}
      />

      {(operationType === CalenderOperationType.All ||
        operationType === CalenderOperationType.Telehealth) && (
        <LocationSelector />
      )}
      {renderControls()}
      {/* {operationType === CalenderOperationType.Telehealth && (
        <>
          <NurseSelector
            facility={facility}
            facilities={facilities}
            onChange={setFacility}
            loading={isFetchingFacilities}
          />
        </>
      )} */}
    </div>
  );
};

export default Toolbar;
