import React from "react";

export default function SegmentButton({ options, selected, onChange }) {
  return (
    <div className="viewSelector">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => onChange(option)}
          className={`views ${selected === option ? "active" : ""} `}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
