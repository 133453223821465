import { setLoading } from "redux/actions/app.actions";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "utils/Notification";
import {
  setMonthlyBookings,
  setAllPendingBookings,
  assignNurseToBooking as assignNurseToBookingAction,
  addBooking,
  updateBooking,
  setDailyBookings,
  setCurrentBooking,
} from "redux/actions/booking.actions";
import { cloneDeep } from "lodash";
import { addNewOrder, setCurrentOrder } from "redux/actions/order.actions";
import {
  setCustomerNoShowBookings,
  setCustomerTotalBookings,
  setCustomerUpcomingBookings,
} from "redux/actions/customerInfo.actions";
import { getRewards } from "./guests.services";
import { injectRewards } from "utils/common";
// /api/Orders/FetchOrders

export const createBooking = (data) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const rewards = await dispatch(getRewards(data.customerId));
    if (rewards?.status === "success") {
      const isRewardsInjected = injectRewards(
        data,
        rewards?.data ?? [],
        data?.facilityId
      ); // mutates the object with latest rewards
      if (isRewardsInjected) {
        data.useWallet = false;
      }
    }
    return request("Orders", "post", data, true)
      .then((response) => {
        dispatch(addBooking(response.data));
        dispatch(addNewOrder(response.data));
        openNotificationWithIcon(
          "success",
          "Success",
          "Booking created successfully!"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.data?.[0] ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

/**
 * @param {object} data - data to be sent to the server
 * @param {number} data.facilityId  - id of the facility
 * @param {string} data.requestedDate  - date of the booking
 * @param {number} data.filterType  - 0 all, 1 Monthly, 2 Weekly, 3 Daily
 * @param {boolean} data.staffAssign  - whether or not staff was assigned
 * @param {number} data.nurseId - id of the nurse
 */
export const getBookings = (data, showLoading = true, signal) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Orders/FetchOrders", "post", data, true, null, signal)
      .then((response) => {
        response.data.forEach((booking) => {
          booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
          booking.id = booking.orderId;
          delete booking.amountPaidUntilNow;
        });
        dispatch(setDailyBookings(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        if (e?.message === "canceled") {
          return;
        }
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getAllPendingBookings = (showLoading = true, signal) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      "Orders/FetchOrders",
      "post",
      {
        filterType: 0,
        facilityIds: [],
      },
      true,
      null,
      {
        signal,
      }
    )
      .then((response) => {
        response.data.forEach((booking) => {
          booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
          booking.id = booking.orderId;
          delete booking.amountPaidUntilNow;
        });
        dispatch(setAllPendingBookings(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        if (e?.message === "canceled") {
          return;
        }
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

/**
 * @param {number} orderId - id of the data to be feteched
 */
export const getBookingDetails = (orderId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/${orderId}`, "get", null, true)
      .then(({ data }) => {
        // response.data.forEach((booking) => {
        //   booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
        //   booking.id = booking.orderId;
        // });
        data.resourceId = data.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
        data.id = data.orderId;
        callback?.(data);
        return {
          status: "success",
          data: data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const assignNurseToBooking = (booking, staffId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(`Orders/${orderId}?staffId=${staffId}`, "patch", null, true)
      .then(async (response) => {
        const newBooking = cloneDeep(booking);
        newBooking.staffId = staffId;
        newBooking.resourceId = staffId;
        dispatch(assignNurseToBookingAction(booking, staffId));
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinician assigned successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateNurseToBooking = (booking, staffId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(`Orders/${orderId}?staffId=${staffId}`, "patch", null, true)
      .then(async (response) => {
        dispatch(assignNurseToBookingAction(booking, staffId));
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinician assigned successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const switchNurseForBooking = (booking, staffId, onFail) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(`Orders/${orderId}?staffId=${staffId}`, "patch", null, true)
      .then(async (response) => {
        const updatedBooking = cloneDeep(booking);
        updatedBooking.staffId = staffId;
        updatedBooking.resourceId = staffId;
        dispatch(updateBooking(updatedBooking, staffId));
        openNotificationWithIcon(
          "success",
          "Success",
          "Clinician assigned successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        onFail?.();
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// public enum OrderStatus
// {
//     Pending = 1,
//     Cancel = 2,
//     Completed = 3,
//     NoShow = 4,
//     Arrived = 5
// }
export const updateOrderStatus = (booking, status, onFail) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(`Orders?orderId=${orderId}`, "patch", status, {
      contentType: "text/json",
    })
      .then(async (response) => {
        const updatedBooking = cloneDeep(booking);
        updatedBooking.orderStatus = status;
        // updatedBooking.staffId = staffId;
        // updatedBooking.resourceId = staffId;
        dispatch(updateBooking(updatedBooking));
        openNotificationWithIcon(
          "success",
          "Success",
          "Order status changed successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        onFail?.();
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const cancelOrder = (booking, shouldCharge) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(
      `Orders?orderId=${orderId}&charge=${shouldCharge}`,
      "patch",
      2,
      {
        contentType: "text/json",
      }
    )
      .then(async (response) => {
        const updatedBooking = cloneDeep(booking);
        updatedBooking.orderStatus = 2;
        // updatedBooking.staffId = staffId;
        // updatedBooking.resourceId = staffId;
        dispatch(updateBooking(updatedBooking));
        openNotificationWithIcon(
          "success",
          "Success",
          "Order status changed successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

// public enum PaymentType
// {
//     Cash = 1,
//     Pay = 2
// }
export const updateOrderPaymentStatus = (booking, status, onFail) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { orderId } = booking;
    return request(`Orders/${orderId}/PaymentType`, "patch", status, {
      contentType: "text/json",
    })
      .then(async (response) => {
        const updatedBooking = cloneDeep(booking);
        updatedBooking.paymentType = status;
        // updatedBooking.resourceId = staffId;
        dispatch(updateBooking(updatedBooking));
        openNotificationWithIcon(
          "success",
          "Success",
          "Payment status changed successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        onFail?.();
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addLineItems = (orderId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/${orderId}/LineItems`, "post", data, true)
      .then(async (response) => {
        // TODO: we probably need to update data here
        // or get orderdetail by id
        return dispatch(getBookingDetails(orderId))
          .then((res) => {
            dispatch(updateBooking(res.data));
            openNotificationWithIcon(
              "success",
              "Success",
              "Service added successfully."
            );
            return {
              status: "success",
              data: response.data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

// export const updateLineItem = (orderId, lineItemId, data) => {
//   return (dispatch) => {
//     dispatch(setLoading(true));

//     return request(
//       `Orders/${orderId}/LineItems/${lineItemId}`,
//       "delete",
//       null,
//       true
//     )
//       .then((deleteResponse) => {
//         return request(`Orders/${orderId}/LineItems`, "post", data, true)
//           .then((addResponse) => {
//             console.log("addResponse", addResponse);
//             return dispatch(getBookingDetails(orderId))
//               .then((detailResponse) => {
//                 openNotificationWithIcon(
//                   "success",
//                   "Success",
//                   "Service updated successfully."
//                 );
//                 dispatch(updateBooking(detailResponse.data));
//                 return {
//                   status: "success",
//                   data: data,
//                 };
//               })
//               .catch((e) => {
//                 openNotificationWithIcon(
//                   "error",
//                   "Error",
//                   e?.response?.message || e?.message || "Something went wrong"
//                 );
//               });
//           })
//           .catch((e) => {
//             openNotificationWithIcon(
//               "error",
//               "Error",
//               e?.response?.message || e?.message || "Something went wrong"
//             );
//           });
//       })
//       .catch((e) => {
//         openNotificationWithIcon(
//           "error",
//           "Error!",
//   e?.response?.data?.[0] ||
// e?.response?.data?.message ||
//   e?.response?.data?.Message ||
//   e?.response?.message ||
//   e?.message ||
//   "Something went wrong";
//         );
//       })
//       .finally(() => {
//         dispatch(setLoading(false));
//       });
//   };
// };

export const updateLineItem = (orderId, lineItemId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(
      `Orders/${orderId}/LineItems/${lineItemId}`,
      "put",
      {
        productId: data[0].productId,
        addOns: data[0].addOns,
      },
      true
    )
      .then((addResponse) => {
        return dispatch(getBookingDetails(orderId))
          .then((detailResponse) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Service updated successfully."
            );
            dispatch(updateBooking(detailResponse.data));
            return {
              status: "success",
              data: data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const deleteLineItem = (orderId, lineItemId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(
      `Orders/${orderId}/LineItems/${lineItemId}`,
      "delete",
      null,
      true
    )
      .then(({ data }) => {
        return dispatch(getBookingDetails(orderId))
          .then((res) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Service deleted successfully."
            );
            dispatch(updateBooking(res.data));
            return {
              status: "success",
              data: res.data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => [dispatch(setLoading(false))]);
  };
};
export const applyVoucher = (orderId, voucherCode, forceApply = false) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderId}/Voucher/${voucherCode}?forceApply=${forceApply}`,
      "put",
      null,
      true
    )
      .then(({ data }) => {
        if (data) {
          data.resourceId = data.staffId; 
          data.id = data.orderId;
          dispatch(setCurrentOrder(data));
          dispatch(setCurrentBooking(data));
        }
      })
      .catch((e) => {
        if (
          e?.response?.data?.[0] ===
          "This voucher has reached its redemption limit"
        ) {
          return {
            status: "error",
            type: "redemptionLimitExceeded",
            message: e?.response?.data?.[0],
          };
        }
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Invalid Code"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
export const removeVoucher = (orderId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(`Orders/${orderId}/Voucher`, "delete", null, true)
      .then(({ data }) => {
        if (data) {
          dispatch(setCurrentOrder(data));
          dispatch(setCurrentBooking(data));
        }
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const completeOrder = (booking, data) => {
  const { orderId } = booking;
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/Complete`, "patch", data, true, { orderId })
      .then(({ data }) => {
        const updatedBooking = cloneDeep(booking);
        updatedBooking.orderStatus = 3;

        // TODO: Update Bookings Reducer
        dispatch(updateBooking(updatedBooking));

        // TODO: Update Orders Reducer
        openNotificationWithIcon(
          "success",
          "Order Completed!",
          "Thank you for your payment!"
        );
        return { status: "success", data: updatedBooking };
        // dispatch(setCurrentOrder(data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.[0] ||
            e.message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updatePaymentMethod = (orderId, paymentMethodId, onFail) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderId}/PaymentMethod?paymentMethodId=${paymentMethodId}`,
      "patch",
      {},
      {
        contentType: "text/json",
      }
    )
      .then(async (response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Payment method changed successfully"
        );
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        onFail?.();
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCustomerTotalBookings = (customerId, showLoading = false) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Orders/Total?customerId=${customerId}`, "get", null, true)
      .then((response) => {
        response.data.forEach((booking) => {
          booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
          booking.id = booking.orderId;
          delete booking.amountPaidUntilNow;
        });
        dispatch(setCustomerTotalBookings(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getCustomerUpcomingBookings = (
  customerId,
  orderStatus = 1,
  showLoading = false
) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      `Orders/Count?customerId=${customerId}&orderStatus=${orderStatus}`,
      "get",
      null,
      true
    )
      .then((response) => {
        response.data.forEach((booking) => {
          booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
          booking.id = booking.orderId;
          delete booking.amountPaidUntilNow;
        });
        dispatch(setCustomerUpcomingBookings(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getCustomerNoShowBookings = (
  customerId,
  orderStatus = 4,
  showLoading = false
) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      `Orders/Count?customerId=${customerId}&orderStatus=${orderStatus}`,
      "get",
      null,
      true
    )
      .then((response) => {
        response.data.forEach((booking) => {
          booking.resourceId = booking.staffId; // UNABLE TO WORK AROUND IT; resourceId need for react-big-calender
          booking.id = booking.orderId;
          delete booking.amountPaidUntilNow;
        });
        dispatch(setCustomerNoShowBookings(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};
export const updateAppointmentDateTime = (orderId, utcDateString) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const urlEncoded = escape(utcDateString);
    return request(
      `Orders/${orderId}/ServiceDate?serviceDate=${urlEncoded}`,
      "patch",
      null,
      true
    )
      .then((addResponse) => {
        return dispatch(getBookingDetails(orderId))
          .then((detailResponse) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Appointment date & time updated successfully."
            );
            dispatch(updateBooking(detailResponse.data));
            return {
              status: "success",
              data: detailResponse?.data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.data?.message ||
                e?.response?.message ||
                e?.message ||
                "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const changeWalletPreferance = (
  orderId,
  useWallet,
  walletId,
  callback
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const walletIdQs = walletId ? `&walletId=${walletId}` : "";
    return request(
      `Orders/${orderId}/ChargePreference?useWallet=${useWallet}${walletIdQs}`,
      "patch",
      null,
      true
    )
      .then(() => {
        return dispatch(getBookingDetails(orderId))
          .then((detailResponse) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Order's wallet preferance updated successfully"
            );
            dispatch(updateBooking(detailResponse.data));
            callback?.();
            return {
              status: "success",
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeLineitemReward = (orderId, lineItemId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderId}/LineItems/${lineItemId}/Rewards`,
      "delete",
      null,
      true
    )
      .then((addResponse) => {
        return dispatch(getBookingDetails(orderId))
          .then((detailResponse) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Reward removed successfully."
            );
            dispatch(updateBooking(detailResponse.data));
            return {
              status: "success",
              data: data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const applyRewardToLineitem = (
  orderId,
  lineItemId,
  rewardId,
  callback
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderId}/LineItems/${lineItemId}/Rewards/${rewardId}`,
      "put",
      null,
      true
    )
      .then(() => {
        return dispatch(getBookingDetails(orderId))
          .then((detailResponse) => {
            dispatch(updateBooking(detailResponse.data));
            callback?.();
            return {
              status: "success",
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

/**
 * @param {number} orderId - id of the order
 */
export const isCancellationPolicyApplicable = (orderId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderId}/IsCancellationPolicyApplicable`,
      "get",
      null,
      true
    )
      .then(({ data }) => {
        return {
          status: "success",
          data: data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally((res) => {
        console.log(res);
        dispatch(setLoading(false));
      });
  };
};

export const removeAllRewards = (orderId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/${orderId}/RemoveAllRewards`, "delete", null, true)
      .then(({ data }) => {
        return dispatch(getBookingDetails(orderId))
          .then((res) => {
            openNotificationWithIcon(
              "success",
              "Success",
              "Rewards removed successfully."
            );
            dispatch(updateBooking(res.data));
            return {
              status: "success",
              data: res.data,
            };
          })
          .catch((e) => {
            openNotificationWithIcon(
              "error",
              "Error",
              e?.response?.message || e?.message || "Something went wrong"
            );
          });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => [dispatch(setLoading(false))]);
  };
};
