import React, { useEffect, useState } from "react";
import "./ProgramsList.scss";
import { useDispatch } from "react-redux";
import { getProgramsAndMemberships } from "services/guests.services";
import { Alert, Divider, Empty, List, Skeleton, Typography } from "antd";
import { PROGRAM_TYPE, getEnumKeyByValue } from "utils/enums";
import ProgramDrawer from "./ProgramDrawer";

import styles from "../GiftCardRow/GiftCardRow.module.scss"

const { Text, Title } = Typography;

export default function ProgramsList({ customerId, paymentMethodId, onSave }) {
  const [data, setData] = useState({
    data: [],
    isLoading: false,
  });
  const [purchaseDrawer, setPurchaseDrawer] = useState(false);
  const dispatch = useDispatch();

  const [showWaitAlert, setshowWaitAlert] = useState(false);

  const loadData = () => {
    if (customerId) {
      // fetch data
      setData((state) => ({
        ...state,
        isLoading: true,
      }));
      dispatch(getProgramsAndMemberships(customerId))
        .then((res) => {
          if (res?.status === "success") {
            setData((state) => ({
              ...state,
              data: res?.data ?? [],
            }));
          }
        })
        .finally(() => {
          setData((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    }
  };
  useEffect(() => {
    loadData();
  }, [customerId]);

  if (data?.isLoading) return <Skeleton />;

  return (
    <div>
      {showWaitAlert && (
        <Alert
          type="success"
          className={styles.giftcardFeedbackAlert}
          closable
          message="Please wait while we are processing your request. This may take about 10 seconds."
        />
      )}
      <Typography.Link
        style={{
          fontFamily: "tradeGothic",
          fontSize: 16,
          fontWeight: 500,
          textDecoration: "underline",
          textAlign: "right",
        }}
        onClick={() => setPurchaseDrawer(true)}
      >
        Add or Cancel Program/Membership
      </Typography.Link>

      <List
        locale={{
          emptyText: "No Programs & Memberships",
        }}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        dataSource={data?.data ?? []}
        renderItem={(item) => {
          const programType = getEnumKeyByValue(PROGRAM_TYPE, item.programType);
          return (
            <div
              style={{
                borderBottom: "1px solid #e8e8e8",
              }}
            >
              <List.Item>
                <div>
                  <Title
                    style={{
                      fontSize: 18,
                      color: "#486baf",
                    }}
                  >
                    {item.programName} ({programType})
                  </Title>
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Selling Price: ${item?.sellingPrice}
                  </Text>
                </div>
              </List.Item>
            </div>
          );
        }}
      />
      <ProgramDrawer
        paymentMethodId={paymentMethodId}
        customerId={customerId}
        visible={purchaseDrawer}
        onClose={() => setPurchaseDrawer(false)}
        purchasedPrograms={data?.data ?? []}
        onSuccess={() => {
          setshowWaitAlert(true);
          setTimeout(() => {
            onSave?.();
            loadData();
            setshowWaitAlert(false);
          }, 10000);
          setPurchaseDrawer(false);
        }}
      />
    </div>
  );
}
