import { Col, Form, Input, Row, Typography } from "antd";
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormDatePicker,
  FormInput,
  FormInputArea,
  FormInputNumber,
  FormPhoneNumber,
  FormRadioGroup,
  FormSelect,
  FormSlider,
} from "components/FormItems/FlatFormItems";
import Signature from "components/Signature/Signature";
import React from "react";
import myxss from "containers/FormsManagement/FormBuilder/form-elements/myxss";
import CustomElement from "../FormBuilder/form-elements/custom-element";
import ImageAnnotator from "components/ImageAnnotator";

import NeuromodularImage from "assets/images/neuromodular_form.png";
import AestheticImage from "assets/images/aestheticdermal_form.png";

const { Title } = Typography;

export const DosageTemplate = (props) => {
  const { data, form, disabled } = props;
  const checkboxValue = Form?.useWatch(`${data?.field_name}_checkbox`, form);

  const { bold_all_child_labels } = data ?? {};
  return (
    <>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={disabled}
            name={`${data?.field_name}_checkbox`}
            style={{ marginRight: 12, marginBottom: 0 }}
          />
        </Col>
        <Col flex="auto">
          <Title
            level={4}
            className="templateLabel"
            style={{
              fontSize: 16,
              paddingTop: 13,
              color: "#000",
              margin: 0,
              lineHeight: 1,
              fontWeight: 400,
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: myxss.process(data.label),
              }}
            ></span>
          </Title>
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            formItemClassName={bold_all_child_labels ? "" : "normalLabel"}
            disabled={disabled}
            required={checkboxValue}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name={`${data?.field_name}_expiration`}
            label="Expiration Date"
            form={form}
            // disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            formItemClassName={bold_all_child_labels ? "" : "normalLabel"}
            disabled={disabled}
            required={checkboxValue}
            // required={formChecked.boostNS500Checked}
            name={`${data?.field_name}_lot`}
            label="Lot Number"
            placeholder="Lot Number"
            // disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            formItemClassName={bold_all_child_labels ? "" : "normalLabel"}
            disabled={disabled}
            required={checkboxValue}
            // required={formChecked.boostNS500Checked}
            name={`${data?.field_name}_route`}
            label="Route"
            placeholder="Route"
            // disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            formItemClassName={bold_all_child_labels ? "" : "normalLabel"}
            disabled={disabled}
            required={checkboxValue}
            // variant="underlined"
            // disabled={chart[0]?.staffSignature}
            // required={formChecked.boostNS500Checked}
            name={`${data?.field_name}_dose`}
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
    </>
  );
};

export const CheckboxInputTemplate = (props) => {
  const { data, form, disabled } = props;
  const checkboxValue = Form?.useWatch(`${data?.field_name}_checkbox`, form);
  return (
    <Row wrap={false}>
      <Col>
        <FormCheckbox
          disabled={disabled}
          name={`${data?.field_name}_checkbox`}
          style={{ marginRight: 12 }}
        />
      </Col>
      <Col flex="auto">
        <FormInput
          variant="underlined"
          disabled={disabled}
          required={!checkboxValue}
          rows={6}
          formItemStyles={{ width: "100%" }}
          name={`${data?.field_name}_input`}
          label={data?.label}
          placeholder="Enter here..."
          formItemClassName="normalLabel"
        />
      </Col>
    </Row>
  );
};

export const Contraindications = (props) => {
  const { data, form, disabled } = props;
  return (
    <FormCheckboxGroup
      formItemClassName="normalLabel"
      disabled={disabled}
      name={data.field_name}
      required={data.required}
      // disabled={iterator.disabled}}
      // name="contraindications"
      inline={data.inline === true}
      label={data.label}
      options={data.contraindication_options?.map?.((option, index) => ({
        label: option.contraindicationName,
        value: option.contraindicationId,
      }))}
      // formItemStyles={{ marginBottom: 12 }}
    />
  );
};

const InputWrapper = ({
  disabled,
  element,
  form,
  signature,
  getSignatureValue,
  clearSignatureValue,
  setSignatureValue,
  signature1,
  getSignatureValue1,
  clearSignatureValue1,
  setSignatureValue1,
  isSignatureDisabled,
  isSignature1Disabled,
  addNotesPortal,
  children,
}) => {
  const requiredDependentOn = Form.useWatch(element.requiredDependentOn, form);
  const isRequiredIf = element.isRequiredIf === true;

  const isRequired =
    (isRequiredIf && requiredDependentOn?.length > 0) ||
    requiredDependentOn === true ||
    (!isRequiredIf && element.required);

  if (element === undefined) {
    return <> </>;
  }

  let classNames = "static";
  if (element.bold) {
    classNames += " bold";
  }
  if (element.italic) {
    classNames += " italic";
  }

  let baseClasses = "";
  if (element.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }
  if (element.element === "LineBreak")
    return (
      <div className={baseClasses}>
        <hr />
      </div>
    );
  else if (element.element === "Paragraph")
    return (
      <div className={baseClasses}>
        <p
          style={{
            fontFamily: "tradeGothic",
            marginBottom: 4,
          }}
          className={`${classNames} formTextContent`}
          dangerouslySetInnerHTML={{
            __html: myxss.process(element.content),
          }}
        />
      </div>
    );
  else if (element.element === "Header") {
    return (
      <div
        className={baseClasses}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          className={`form-header ${classNames}`}
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 24,
            color: "#000",
          }}
          dangerouslySetInnerHTML={{
            __html: myxss.process(element.content),
          }}
        />
      </div>
    );
  } else if (element.element === "TextInput") {
    return (
      <FormInput
        disabled={disabled}
        name={element.field_name}
        label={element.label}
        required={isRequired}
        formItemClassName="normalLabel"
        variant={element.variant === "underlined" ? "underlined" : ""}
      />
    );
  } else if (element.element === "Dropdown") {
    return (
      <FormSelect
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        label={element.label}
        options={element.options.map((option, index) => ({
          label: option.text,
          value: `${option.text}${index}`,
        }))}
        renderLabel={"label"}
        renderValue={"value"}
        required={isRequired}
        variant={element.variant === "underlined" ? "underlined" : ""}
      />
    );
  } else if (element.element === "RadioButtons") {
    return (
      <>
        <FormRadioGroup
          formItemClassName="normalLabel"
          disabled={disabled}
          label={element.label}
          name={element.field_name}
          required={element.required}
          inline={element.inline === true}
          options={element.options.map((option, index) => ({
            label: option.text,
            value: `${option.text}${index}`,
          }))}
        />
      </>
    );
  } else if (element.element === "Checkboxes") {
    return (
      <FormCheckboxGroup
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        required={element.required}
        // disabled={iterator.disabled}}
        // name="contraindications"
        inline={element.inline === true}
        label={element.label}
        options={element.options.map((option, index) => ({
          label: option.text,
          value: `${option.text}${index}`,
        }))}
        // formItemStyles={{ marginBottom: 12 }}
      />
    );
  } else if (element.element === "EmailInput") {
    return (
      <FormInput
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        label={element.label}
        type="email"
        required={isRequired}
        variant={element.variant === "underlined" ? "underlined" : ""}
      />
    );
  } else if (element.element === "NumberInput") {
    return (
      <FormInputNumber
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        label={element.label}
        required={isRequired}
        variant={element.variant === "underlined" ? "underlined" : ""}
      />
    );
  } else if (element.element === "PhoneNumber") {
    return (
      <FormPhoneNumber
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        variant={element.variant === "underlined" ? "underlined" : ""}
        label={element.label}
        required={isRequired}
      />
    );
  } else if (element.element === "TextArea") {
    return (
      <>
        <FormInputArea
          disabled={disabled}
          name={element.field_name}
          variant="underlined"
          rows={6}
          label={element.label}
          required={isRequired}
          formItemClassName="normalLabel"
          // placeholder="Instructions"
          // formItemStyles={{ marginBottom: 0 }}
        />
      </>
    );
  } else if (element.element === "DatePicker") {
    return (
      <FormDatePicker
        formItemClassName="normalLabel"
        disabled={disabled}
        name={element.field_name}
        variant={element.variant === "underlined" ? "underlined" : ""}
        label={element.label}
        form={form}
        required={isRequired}

        // disabled={chart[0]?.staffSignature}
      />
    );
  } else if (element.element === "Range") {
    let marks = {};
    for (let i = element.min_value; i <= element.max_value; i++) {
      marks[i] = `${i}`;
    }
    return (
      <FormSlider
        formItemClassName="normalLabel"
        disabled={disabled}
        // disabled={chart[0]?.staffSignature}
        // required={false}
        name={element.field_name}
        label={element.label}
        step={1}
        min={Number(element.min_value || 0)}
        max={Number(element.max_value || 0)}
        marks={marks}
      />
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "GuestSignature"
  ) {
    return (
      <>
        {element.showLabel && (
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
              marginTop: 28,
            }}
          >
            Guest Signature
          </h1>
        )}

        <Signature
          name="signature"
          signature={signature}
          disabled={isSignatureDisabled}
        />
      </>
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "NurseSignature"
  ) {
    return (
      <>
        {element.showLabel && (
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
              marginTop: 28,
            }}
          >
            Nurse Signature
          </h1>
        )}
        <Signature
          name="signature1"
          signature={signature1}
          disabled={isSignature1Disabled}
        />
      </>
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "StaffNurseSignature"
  ) {
    return (
      <div id="signatureContainer">
        {addNotesPortal && <div id="notesPortal"></div>}

        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
            marginTop: 28,
          }}
        >
          Clinician Signature
        </h1>
        <Signature
          name="sigature"
          signature={signature}
          disabled={isSignatureDisabled}
        />
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
            marginTop: 28,
          }}
        >
          Nurse Practitioner/Physician Signature
        </h1>
        <Signature
          name="signature1"
          signature={signature1}
          disabled={isSignature1Disabled}
        />
      </div>
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "GoodFaithSignature"
  ) {
    return (
      <div id="signatureContainer">
        {/* {addNotesPortal && <div id="notesPortal"></div>} */}
        {children}

        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
            marginTop: 28,
          }}
        >
          NP/Physician 1 Signature
        </h1>
        <Signature
          name="sigature"
          signature={signature}
          disabled={isSignatureDisabled}
        />
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
            marginTop: 28,
          }}
        >
          NP/Physician 2 Signature
        </h1>
        <Signature
          name="signature1"
          signature={signature1}
          disabled={isSignature1Disabled}
        />
      </div>
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "CheckboxInputTemplate"
  ) {
    return (
      <CheckboxInputTemplate form={form} data={element} disabled={disabled} />
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "DosageTemplate"
  ) {
    return <DosageTemplate form={form} data={element} disabled={disabled} />;
  } else if (
    element.element === "CustomElement" &&
    element.key === "Contraindications"
  ) {
    return <Contraindications form={form} data={element} disabled={disabled} />;
  } else if (
    element.element === "CustomElement" &&
    element.key === "ImageWidget"
  ) {
    return (
      <>
        <ImageAnnotator
          image={NeuromodularImage}
          form={form}
          name={element.field_name}
          disabled={disabled}
        />
      </>
    );
  } else if (
    element.element === "CustomElement" &&
    element.key === "ImageWidget2"
  ) {
    return (
      <>
        <ImageAnnotator
          image={AestheticImage}
          form={form}
          name={element.field_name}
          disabled={disabled}
        />
      </>
    );
  } else return <div>{element.content}</div>;
};

export default function FormParsedUI({
  disabled,
  addNotesPortal = false,
  data,
  form,
  signature,
  getSignatureValue,
  clearSignatureValue,
  setSignatureValue,
  signature1,
  getSignatureValue1,
  clearSignatureValue1,
  setSignatureValue1,
  isSignatureDisabled,
  isSignature1Disabled,
  children,
}) {
  const renderElement = (element) => {
    return (
      <InputWrapper
        key={element.id}
        element={element}
        form={form}
        disabled={disabled}
        signature={signature}
        getSignatureValue={getSignatureValue}
        clearSignatureValue={clearSignatureValue}
        setSignatureValue={setSignatureValue}
        signature1={signature1}
        getSignatureValue1={getSignatureValue1}
        clearSignatureValue1={clearSignatureValue1}
        setSignatureValue1={setSignatureValue1}
        isSignatureDisabled={isSignatureDisabled}
        isSignature1Disabled={isSignature1Disabled}
        addNotesPortal={addNotesPortal}
        children={children}
      />
    );
  };

  const renderMultipleRow = (record, data) => {
    const item1 = data?.find((item) => item.id === record.childItems?.[0]);
    const item2 = data?.find((item) => item.id === record.childItems?.[1]);
    const item3 = data?.find((item) => item.id === record.childItems?.[2]);
    const item4 = data?.find((item) => item.id === record.childItems?.[3]);
    if (record.element === "TwoColumnRow") {
      return (
        <Row gutter={[36, 0]}>
          {item1 && (
            <Col xs={24} md={12}>
              {renderElement(item1)}
            </Col>
          )}
          {item2 && (
            <Col xs={24} md={12}>
              {renderElement(item2)}
            </Col>
          )}
        </Row>
      );
    } else if (record.element === "ThreeColumnRow") {
      return (
        <Row gutter={[36, 0]}>
          {item1 && (
            <Col xs={24} md={8}>
              {renderElement(item1)}
            </Col>
          )}
          {item2 && (
            <Col xs={24} md={8}>
              {renderElement(item2)}
            </Col>
          )}
          {item3 && (
            <Col xs={24} md={8}>
              {renderElement(item3)}
            </Col>
          )}
        </Row>
      );
    } else if (record.element === "MultiColumnRow") {
      return (
        <Row gutter={[36, 0]}>
          {item1 && (
            <Col xs={24} md={6}>
              {renderElement(item1)}
            </Col>
          )}
          {item2 && (
            <Col xs={24} md={6}>
              {renderElement(item2)}
            </Col>
          )}
          {item3 && (
            <Col xs={24} md={6}>
              {renderElement(item3)}
            </Col>
          )}
          {item4 && (
            <Col xs={24} md={6}>
              {renderElement(item4)}
            </Col>
          )}
        </Row>
      );
    } else return <div></div>;
  };

  return (
    <div>
      {data
        ?.filter?.((iterator) => iterator.parentId === undefined)
        ?.map?.((iterator) => {
          if (
            iterator.element === "TwoColumnRow" ||
            iterator.element === "ThreeColumnRow" ||
            iterator.element === "MultiColumnRow"
          ) {
            return renderMultipleRow(iterator, data);
          } else {
            return renderElement(iterator);
          }
        })}
    </div>
  );
}
