import moment from "moment";
import {
  BENEFIT_TYPE,
  CalenderOperationType,
  EXCLUSION_TYPE,
  ProductType,
} from "./enums";

export const generateSlots = (date) => {
  let offsetDate = moment(date);
  const isScheduledDateToday =
    moment(date).format("DD MM YYYY") === moment().format("DD MM YYYY");
  // if (!isScheduledDateToday) {
  offsetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  // }

  let hour = offsetDate.hour();
  let minute = offsetDate.minute();

  // Rounding minute on 30 mins interval
  // if (minute <= 30) offsetDate.set({ minute: 30 });
  // if (minute > 30) offsetDate.set({ hour: hour + 1, minute: 0 });

  let shiftStart = moment(offsetDate).set({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  let shiftEnd = moment(offsetDate).set({
    hours: 24,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const timeslots = [];

  do {
    if (offsetDate.isSameOrAfter(shiftStart))
      timeslots.push({
        label: offsetDate.format("LT"),
        value: offsetDate.format("LT"),
        // disabled: disabled,
      });
    offsetDate = offsetDate.add(30, "minute");
  } while (offsetDate.isBefore(shiftEnd));

  return timeslots;
};

const generateMobileSlots = (n, date) => {
  let offsetDate = moment();
  let scheduledDate = moment(date);
  const isScheduledDateToday =
    scheduledDate.format("DD MM YYYY") === moment().format("DD MM YYYY");
  // if (!isScheduledDateToday) {
  offsetDate = moment(scheduledDate);
  offsetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  scheduledDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  // }

  const hoursPlusNMoment = moment().add(n, "hours");
  const currentHr24Plus2 = 0;
  const slots = [];
  for (let i = 0; i < 24; i++) {
    let hr = currentHr24Plus2 + i;
    let date = moment(offsetDate);
    date.hour(hr);

    if (
      1
      // date >= scheduledDate &&
      // !(date.format("DD") !== scheduledDate.format("DD")) &&
      // date.isAfter(hoursPlusNMoment)
    ) {
      let disabled = false;
      let preDate = moment(date).set({
        // hours: 8,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      let postDate = moment(date).set({
        // hours: isMobile ? 20 : 19,
        hours: 24,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      if (date.isBefore(preDate)) {
        continue;
      } else if (date.isAfter(postDate)) {
        continue;
        disabled = true;
      }
      slots.push({
        label: `${moment(hr, "hh").format("LT")}`,
        value: moment(hr, "hh").format("LT"),
        // disabled: disabled,
      });
    }
  }
  return slots;
};

export const generateHourlyTimeslotsAfterNHours = (n, date, isMobile) => {
  return generateSlots(date);
};

export const checkIsMobileBooking = (booking) => {
  return booking?.operationTypeId === 1;
};
export const checkIsClinicBooking = (booking) => {
  return booking?.operationTypeId === 2;
};

export const checkIsTeleHealthBooking = (booking) => {
  return booking?.operationTypeId === 3;
};

export const isUserNurse = (user) => {
  const roleName = user?.roleName?.trim?.();
  return (
    roleName === "Nurse" ||
    roleName === "Nurse Practitioner" ||
    roleName === "Nurse Lead"
  );
};

export const isNurseRole = (user, roles) => {
  const role = roles?.find?.((role) => role?.roleId === user?.roleId);
  const roleName = user?.roleName?.trim?.();
  return (
    roleName === "Nurse" ||
    roleName === "Nurse Practitioner" ||
    roleName === "Nurse Lead"
  );
};

export const verifyRoutePermission = (route, permissions, ignore) => {
  if (ignore) return true;

  if (
    route?.name === "calendar" &&
    !permissions?.find((permission) => {
      return permission?.permissionName === "Scheduling";
    })
  )
    return false;

  if (
    route?.name === "staff" &&
    !permissions?.find((permission) => {
      return permission?.permissionName === "Settings";
    })
  )
    return false;

  if (
    route?.name === "settings" &&
    !permissions?.find((permission) => {
      return permission?.permissionName === "Settings";
    })
  )
    return false;

  return true;
};

export const addonsTextReducer = (prev, curr, currentIndex, array) => {
  let seperator = ",";
  if (!prev) seperator = "";
  if (currentIndex === array.length - 1) seperator = " and ";
  if (array?.length <= 1) seperator = "";
  return prev + `${seperator} ` + curr?.productName;
};

export const isDosageTemplate = (element) => {
  if (element?.key === "DosageTemplate") return true;
  return false;
};

export const isFormBuilderInputField = (element) => {
  if (
    element.element === "TextInput" ||
    element.element === "EmailInput" ||
    element.element === "NumberInput" ||
    element.element === "PhoneNumber" ||
    element.element === "TextArea" ||
    element.element === "DatePicker" ||
    element.element === "Dropdown"
  ) {
    return true;
  }
  return false;
};
export const arrayCommaSeperatedReducer = (prev, curr, currentIndex, array) => {
  let seperator = ",";
  if (!prev) seperator = "";
  if (currentIndex === array.length - 1) seperator = " and ";
  if (array?.length <= 1) seperator = "";
  return prev + `${seperator} ` + curr;
};

export function format_number(number, num_decimals, include_comma) {
  return number.toLocaleString("en-US", {
    useGrouping: include_comma,
    minimumFractionDigits: num_decimals,
    maximumFractionDigits: num_decimals,
  });
}

export const generateHourlySlots = (startTime = "09:00", endTime = "18:00") => {
  const startDate = moment();
  startDate.set({
    hours: startTime.split(":")[0],
    minutes: startTime.split(":")[1],
    seconds: 0,
    milliseconds: 0,
  });

  const endDate = moment();
  endDate.set({
    hours: endTime.split(":")[0],
    minutes: endTime.split(":")[1],
    seconds: 0,
    milliseconds: 0,
  });

  let iterator = moment(startDate);
  const slots = [];
  while (iterator <= endDate) {
    slots.push({
      label: iterator.format("LT"),
      value: iterator.format("LT"),
      disabled: true,
    });
    iterator = iterator.add(30, "minutes");
  }

  return slots;
};

export const getLegacyChartURL = (orderId, lineItemId, productName) => {
  switch (productName) {
    case "Abdominal Discomfort":
      return `/dashboard/charts/${orderId}/abdominalDiscomfort/${lineItemId}`;
    case "Allergies":
      return `/dashboard/charts/${orderId}/allergies/${lineItemId}`;
    case "Cold & Flu":
      return `/dashboard/charts/${orderId}/coldAndFlu/${lineItemId}`;
    case "Covid Testing":
      return `/dashboard/charts/${orderId}/covidTesting/${lineItemId}`;
    case "Dehydration":
      return `/dashboard/charts/${orderId}/dehydration/${lineItemId}`;
    case "Energy":
      return `/dashboard/charts/${orderId}/energyBoost/${lineItemId}`;
    case "Fatigue & Jet Lag":
      return `/dashboard/charts/${orderId}/fatigueAndJetLag/${lineItemId}`;
    case "Hangover Relief":
      return `/dashboard/charts/${orderId}/hangoverRelief/${lineItemId}`;
    case "NAD+ (High Dose)":
      return `/dashboard/charts/${orderId}/highDoseNAD/${lineItemId}`;
    case "Hydration":
      return `/dashboard/charts/${orderId}/hydration/${lineItemId}`;
    case "IM Injection":
      return `/dashboard/charts/${orderId}/iMInjection/${lineItemId}`;
    case "Migraine":
      return `/dashboard/charts/${orderId}/migraine/${lineItemId}`;
    case "Mental Clarity":
      return `/dashboard/charts/${orderId}/mentalClarity/${lineItemId}`;
    case "Immunity & Defense":
      return `/dashboard/charts/${orderId}/immunityBoostDefense/${lineItemId}`;
    case "NAD+ (Low Dose)":
      return `/dashboard/charts/${orderId}/lowDoseNAD/${lineItemId}`;
    case "Myers Cocktail":
      return `/dashboard/charts/${orderId}/myersCocktail/${lineItemId}`;
    case "Nausea & Vomiting Relief":
      return `/dashboard/charts/${orderId}/nauseaAndVomiting/${lineItemId}`;
    case "Performance & Recovery":
      return `/dashboard/charts/${orderId}/performanceAndRecovery/${lineItemId}`;
    case "Weight Management":
      return `/dashboard/charts/${orderId}/weightManagement/${lineItemId}`;
    default:
      return `/dashboard/charts/${orderId}/beautifyAndGlow/${lineItemId}`;
  }
};

export const openChartURL = (
  orderId,
  lineItemId,
  { isLegacy = false, productName = "" }
) => {
  let url = "";
  if (isLegacy) url = getLegacyChartURL(orderId, lineItemId, productName);
  else url = `/dashboard/chartsv2/${orderId}/${lineItemId}`;
  window.open(url, "_blank");
};

export const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = Math.round(mins % 60);
  // h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `(${h}hr ${m}min)`;
};
export function hoursList(length, text) {
  let hours = [];
  for (let index = 1; index < length; index++) {
    hours.push({ label: `${index} ${text}`, value: index });
  }
  return hours;
}

export const getProductTypeFromOperation = (operationTypeId) => {
  return operationTypeId === CalenderOperationType.Telehealth
    ? ProductType.Telehealth
    : ProductType.Service;
};

export const checkForProductExcludeType = (
  myreward = {},
  values = {},
  excludeRewards = []
) => {
  let availableCustomerRewardIds = [];
  if (myreward.productExcludeType === EXCLUSION_TYPE["All Included"]) {
    // saari products pe ye reward apply krdo
    availableCustomerRewardIds = myreward?.customerRewardIds;
  } else if (myreward.productExcludeType === EXCLUSION_TYPE["Only Included"]) {
    // jo productIds ki collection mai ho sirf unhi pe apply krdo
    if (myreward.productIds.includes(values.productId)) {
      availableCustomerRewardIds = myreward?.customerRewardIds;
    }
  } else if (
    myreward.productExcludeType === EXCLUSION_TYPE["All Included Except"]
  ) {
    // jo productIds ki collection mai ho unke elawa sb pe apply krdo
    if (!myreward.productIds.includes(values.productId)) {
      availableCustomerRewardIds = myreward?.customerRewardIds;
    }
  }

  for (let customerRewardId of availableCustomerRewardIds) {
    if (
      !excludeRewards.includes(customerRewardId) ||
      !!myreward?.redemptionLimit
    ) {
      return customerRewardId;
    }
    return;
  }

  return;
};

export const getRewardId = (rewards, values, facilityId, excludeRewards) => {
  let rewardId = undefined;
  for (let myreward of rewards) {
    const dateIsBefore = moment().isBefore(moment(myreward.expiryDateUtc));

    if (
      !dateIsBefore ||
      (!myreward?.applications?.includes(2) &&
        myreward?.benefitType !== BENEFIT_TYPE.REFERAL)
    ) {
      // if includes 1 (guest) and 2 (backoffice) then it means it is applicable for both
      continue;
    }
    if (myreward.facilityExcludeType === EXCLUSION_TYPE["All Included"]) {
      // koi b facility ho us pe apply hoga reward   AllIncluded = 1,
      rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
    } else if (
      myreward.facilityExcludeType === EXCLUSION_TYPE["Only Included"]
    ) {
      // jo facility ho us pe apply hoga reward OnlyInclude = 2,
      if (myreward?.facilityIds?.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    } else if (
      myreward.facilityExcludeType === EXCLUSION_TYPE["All Included Except"]
    ) {
      // all include except facility list jo aarhi ho AllIncludeExcept = 3
      if (!myreward?.facilityIds.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    }
    if (rewardId) return rewardId;
  }
  return;
  // customerRewardIds se koi b ID utha k replace krna hoga order mai
};

export const getFirstRewardIdFromRewards = (
  rewards,
  values,
  facilityId,
  excludeRewards
) => {
  let rewardIds = [];
  for (let myreward of rewards) {
    const dateIsBefore = moment().isBefore(moment(myreward.expiryDateUtc));
    let reward = null;
    if (
      !dateIsBefore ||
      (!myreward?.applications?.includes(2) &&
        myreward?.benefitType !== BENEFIT_TYPE.REFERAL)
    ) {
      // if includes 1 (guest) and 2 (backoffice) then it means it is applicable for both
      continue;
    } else if (
      myreward.facilityExcludeType === EXCLUSION_TYPE["All Included"]
    ) {
      // koi b facility ho us pe apply hoga reward   AllIncluded = 1,
      reward = checkForProductExcludeType(myreward, values, excludeRewards);
    } else if (
      myreward.facilityExcludeType === EXCLUSION_TYPE["Only Included"]
    ) {
      // jo facility ho us pe apply hoga reward OnlyInclude = 2,
      if (myreward?.facilityIds?.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        reward = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    } else if (
      myreward.facilityExcludeType === EXCLUSION_TYPE["All Included Except"]
    ) {
      // all include except facility list jo aarhi ho AllIncludeExcept = 3
      if (!myreward?.facilityIds.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        reward = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    }
    if (!!reward) rewardIds.push(reward);
  }
  return rewardIds;
  // customerRewardIds se koi b ID utha k replace krna hoga order mai
};

export const getRewardsText = (item) => {
  let text = "";
  if (item.benefitType == BENEFIT_TYPE["Discount Percentage"]) {
    text = `Discount applied  ${item.benefitValue}% `;
  } else if (item.benefitType == BENEFIT_TYPE["Discount Amount"]) {
    text = `Discount applied fixed discount of $${Number(
      item.discountValue ?? 0
    ).toFixed(2)}`;
  } else if (item.benefitType == BENEFIT_TYPE["Bonus Service"]) {
    text = `Bonus Service`;
  } else if (item.benefitType == BENEFIT_TYPE["REFERAL"]) {
    text = `Referral bonus of $${Number(item.discountValue ?? 0).toFixed(2)}`;
  }
  return text;
};

export const getProgramText = (item) => {
  let text = "";
  if (item.benefitType == BENEFIT_TYPE["Discount Percentage"]) {
    text = `Discount:  ${item.benefitValue}% `;
  } else if (item.benefitType == BENEFIT_TYPE["Discount Amount"]) {
    text = `Discount: Fixed discount of $${Number(
      item.benefitValue ?? 0
    ).toFixed(2)}`;
  } else if (item.benefitType == BENEFIT_TYPE["Bonus Service"]) {
    text = `Bonus Service`;
  } else if (item.benefitType == BENEFIT_TYPE["REFERAL"]) {
    text = `Referral bonus of $${Number(item.benefitValue ?? 0).toFixed(2)}`;
  }
  return text;
};

export const getDefaultDaySlots = () => {
  return [
    {
      value: 1,
      disabled: true,
      date: "2023-02-28T19:00:00.000Z",
    },
    {
      value: 2,
      disabled: true,
      date: "2023-03-01T19:00:00.000Z",
    },
    {
      value: 3,
      disabled: true,
      date: "2023-03-02T19:00:00.000Z",
    },
    {
      value: 4,
      disabled: true,
      date: "2023-03-03T19:00:00.000Z",
    },
    {
      value: 5,
      disabled: true,
      date: "2023-03-04T19:00:00.000Z",
    },
    {
      value: 6,
      disabled: true,
      date: "2023-03-05T19:00:00.000Z",
    },
    {
      value: 7,
      disabled: true,
      date: "2023-03-06T19:00:00.000Z",
    },
    {
      value: 8,
      disabled: true,
      date: "2023-03-07T19:00:00.000Z",
    },
    {
      value: 9,
      disabled: true,
      date: "2023-03-08T19:00:00.000Z",
    },
    {
      value: 10,
      disabled: true,
      date: "2023-03-09T19:00:00.000Z",
    },
    {
      value: 11,
      disabled: true,
      date: "2023-03-10T19:00:00.000Z",
    },
    {
      value: 12,
      disabled: true,
      date: "2023-03-11T19:00:00.000Z",
    },
    {
      value: 13,
      disabled: true,
      date: "2023-03-12T19:00:00.000Z",
    },
    {
      value: 14,
      disabled: true,
      date: "2023-03-13T19:00:00.000Z",
    },
    {
      value: 15,
      disabled: true,
      date: "2023-03-14T19:00:00.000Z",
    },
    {
      value: 16,
      disabled: true,
      date: "2023-03-15T19:00:00.000Z",
    },
    {
      value: 17,
      disabled: true,
      date: "2023-03-16T19:00:00.000Z",
    },
    {
      value: 18,
      disabled: true,
      date: "2023-03-17T19:00:00.000Z",
    },
    {
      value: 19,
      disabled: true,
      date: "2023-03-18T19:00:00.000Z",
    },
    {
      value: 20,
      disabled: true,
      date: "2023-03-19T19:00:00.000Z",
    },
    {
      value: 21,
      disabled: true,
      date: "2023-03-20T19:00:00.000Z",
    },
    {
      value: 22,
      disabled: true,
      date: "2023-03-21T19:00:00.000Z",
    },
    {
      value: 23,
      disabled: true,
      date: "2023-03-22T19:00:00.000Z",
    },
    {
      value: 24,
      disabled: true,
      date: "2023-03-23T19:00:00.000Z",
    },
    {
      value: 25,
      disabled: true,
      date: "2023-03-24T19:00:00.000Z",
    },
    {
      value: 26,
      disabled: true,
      date: "2023-03-25T19:00:00.000Z",
    },
    {
      value: 27,
      disabled: true,
      date: "2023-03-26T19:00:00.000Z",
    },
    {
      value: 28,
      disabled: true,
      date: "2023-03-27T19:00:00.000Z",
    },
    {
      value: 29,
      disabled: true,
      date: "2023-03-28T19:00:00.000Z",
    },
    {
      value: 30,
      disabled: true,
      date: "2023-03-29T19:00:00.000Z",
    },
    {
      value: 31,
      disabled: true,
      date: "2023-03-30T19:00:00.000Z",
    },
    {
      value: "",
      disabled: true,
      date: "",
    },
    {
      value: "",
      disabled: true,
      date: "",
    },
    {
      value: "",
      disabled: true,
      date: "",
    },
    {
      value: "",
      disabled: true,
      date: "",
    },
  ];
};

export const PercentageArray = [
  {
    value: 1,
    label: "1%",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 3,
    label: "3%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 6,
    label: "6%",
  },
  {
    value: 7,
    label: "7%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 9,
    label: "9%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 11,
    label: "11%",
  },
  {
    value: 12,
    label: "12%",
  },
  {
    value: 13,
    label: "13%",
  },
  {
    value: 14,
    label: "14%",
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 16,
    label: "16%",
  },
  {
    value: 17,
    label: "17%",
  },
  {
    value: 18,
    label: "18%",
  },
  {
    value: 19,
    label: "19%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 21,
    label: "21%",
  },
  {
    value: 22,
    label: "22%",
  },
  {
    value: 23,
    label: "23%",
  },
  {
    value: 24,
    label: "24%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 26,
    label: "26%",
  },
  {
    value: 27,
    label: "27%",
  },
  {
    value: 28,
    label: "28%",
  },
  {
    value: 29,
    label: "29%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 31,
    label: "31%",
  },
  {
    value: 32,
    label: "32%",
  },
  {
    value: 33,
    label: "33%",
  },
  {
    value: 34,
    label: "34%",
  },
  {
    value: 35,
    label: "35%",
  },
  {
    value: 36,
    label: "36%",
  },
  {
    value: 37,
    label: "37%",
  },
  {
    value: 38,
    label: "38%",
  },
  {
    value: 39,
    label: "39%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 41,
    label: "41%",
  },
  {
    value: 42,
    label: "42%",
  },
  {
    value: 43,
    label: "43%",
  },
  {
    value: 44,
    label: "44%",
  },
  {
    value: 45,
    label: "45%",
  },
  {
    value: 46,
    label: "46%",
  },
  {
    value: 47,
    label: "47%",
  },
  {
    value: 48,
    label: "48%",
  },
  {
    value: 49,
    label: "49%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 51,
    label: "51%",
  },
  {
    value: 52,
    label: "52%",
  },
  {
    value: 53,
    label: "53%",
  },
  {
    value: 54,
    label: "54%",
  },
  {
    value: 55,
    label: "55%",
  },
  {
    value: 56,
    label: "56%",
  },
  {
    value: 57,
    label: "57%",
  },
  {
    value: 58,
    label: "58%",
  },
  {
    value: 59,
    label: "59%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 61,
    label: "61%",
  },
  {
    value: 62,
    label: "62%",
  },
  {
    value: 63,
    label: "63%",
  },
  {
    value: 64,
    label: "64%",
  },
  {
    value: 65,
    label: "65%",
  },
  {
    value: 66,
    label: "66%",
  },
  {
    value: 67,
    label: "67%",
  },
  {
    value: 68,
    label: "68%",
  },
  {
    value: 69,
    label: "69%",
  },
  {
    value: 70,
    label: "70%",
  },
  {
    value: 71,
    label: "71%",
  },
  {
    value: 72,
    label: "72%",
  },
  {
    value: 73,
    label: "73%",
  },
  {
    value: 74,
    label: "74%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 76,
    label: "76%",
  },
  {
    value: 77,
    label: "77%",
  },
  {
    value: 78,
    label: "78%",
  },
  {
    value: 79,
    label: "79%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 81,
    label: "81%",
  },
  {
    value: 82,
    label: "82%",
  },
  {
    value: 83,
    label: "83%",
  },
  {
    value: 84,
    label: "84%",
  },
  {
    value: 85,
    label: "85%",
  },
  {
    value: 86,
    label: "86%",
  },
  {
    value: 87,
    label: "87%",
  },
  {
    value: 88,
    label: "88%",
  },
  {
    value: 89,
    label: "89%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 91,
    label: "91%",
  },
  {
    value: 92,
    label: "92%",
  },
  {
    value: 93,
    label: "93%",
  },
  {
    value: 94,
    label: "94%",
  },
  {
    value: 95,
    label: "95%",
  },
  {
    value: 96,
    label: "96%",
  },
  {
    value: 97,
    label: "97%",
  },
  {
    value: 98,
    label: "98%",
  },
  {
    value: 99,
    label: "99%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const getProgramNameFromBenefitType = (benefitType) => {
  if (benefitType === BENEFIT_TYPE["Bonus Service"]) return "Program";
  else if (
    benefitType === BENEFIT_TYPE["Discount Amount"] ||
    benefitType === BENEFIT_TYPE["Discount Percentage"]
  )
    return "Membership";
  else if (benefitType === BENEFIT_TYPE.REFERAL) return "Referral";
  return "";
};

export const injectRewards = (data = {}, rewards = [], facilityId) => {
  let availedRewards = [];
  let isRewardsAttached = false
  for (let lineItem of data.lineItems) {
    const rewardId = getRewardId(rewards, lineItem, facilityId, availedRewards);
    if (rewardId) {
      isRewardsAttached = true
      lineItem.rewardId = rewardId;
      availedRewards.push(rewardId);
    }
    // eslint-disable-next-line no-loop-func
    lineItem.addOns = lineItem.addOns.map((addOn) => {
      const rewardId = getRewardId(rewards, addOn, facilityId, availedRewards);
      if (rewardId) {
        isRewardsAttached = true;
        addOn.rewardId = rewardId;
        availedRewards.push(rewardId);
      }
      return addOn;
    });
    return isRewardsAttached
  }
};
