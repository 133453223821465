import React, { useEffect, useRef } from "react";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { Checkbox, Col, Form, Row, Typography } from "antd";

import styles from "./EmailModal.module.scss";
import {
  FormCheckbox,
  FormInput,
  FormInputArea,
} from "components/FormItems/FlatFormItems";
import { useDispatch } from "react-redux";
import { sendEmail } from "services/guests.services";
import { setLoading } from "redux/actions/app.actions";

export default function EmailModal({ visible, onClose, user, saveText }) {
  const submitBtnRef = useRef();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      closable={true}
      destroyOnClose={true}
      className="stripeAddCardModal"
      headerBorder={false}
      width="620px"
      title="Send Email To Guest"
      onCancel={() => onClose()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={[
        <Button
          style={{ height: 50 }}
          rounded={true}
          onClick={() => {
            submitBtnRef?.current?.click();
          }}
        >
          {saveText}
        </Button>,
      ]}
      //   cancelText="No"
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          const content = values.content;
          const contentWithLineBreaks = content.replace(/\n/g, "<br />");
          const payload = {
            recipients: [user.email],
            subject: values.subject,
            content: contentWithLineBreaks,
          };
          dispatch(sendEmail(user.customerId, payload, true)).then(() => {
            onClose();
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 16]}>
          <Col sm={12} xs={24}>
            <FormInput
              readOnly={true}
              variant="underlined"
              name="to"
              label="To"
              placeholder="Email Address"
              initialValue={user.email}
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col sm={12} xs={24}>
            <FormInput
              readOnly={true}
              variant="underlined"
              name="from"
              label="From"
              placeholder="Email Address"
              initialValue={"scheduling@liquidmobileiv.com"}
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="subject"
              label="Subject"
              placeholder="Email Subject"
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInputArea
              variant="underlined"
              rows={6}
              name="content"
              label="Body"
              // placeholder="B"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          {/* <Col xs={24}>
            <FormCheckbox
              name="respondable"
              // label="Able"
              formItemStyles={{ marginBottom: 12 }}
              initialValue={true}
            >
              Able to respond?
            </FormCheckbox>
          </Col> */}
        </Row>
        <button type="submit" ref={submitBtnRef} hidden={true} />
      </Form>
    </Modal>
  );
}
