import React, { useEffect, useState } from "react";
import { Form, Col, Divider, Row, Typography } from "antd";

import "./Miscellaneous.style.scss";
import Switch from "components/Switch/Switch.index";
import { FormSelect } from "components/FormItems/FlatFormItems";
import CancellationPolicy from "components/CancellationPolicy/CancellationPolicy.index";
import {
  updateConfigByKey,
  getAllCancellationPolicies,
  getConfigByKey,
} from "services/misc.service";
import { useDispatch } from "react-redux";
import { hoursList } from "utils/common";
import { useForm } from "antd/lib/form/Form";
import GiftCardEditModal from "../Programs/components/GiftCardEditModal";
import ConfigAutoSequencingModal from "components/ConfigAutoSequencingModal.js";
import GoodFaithExamPolicyModal from "components/GoodFaith/components/GoodFaithTermsModal";
import GoodFaith from "components/GoodFaith/GoodFaith.index";
import PromoGiftCardEditModal from "../Programs/components/PromoGiftCardEditModal";

const { Title, Text, Link } = Typography;

export default function Miscellaneous() {
  const dispatch = useDispatch();
  const [form] = useForm();

  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [showPromoGiftCardModal, setShowPromoGiftCardModal] = useState(false);
  const [refreshPolicies, setRefreshPolicies] = useState(true);
  const [options, setOptions] = useState([...hoursList(61, "Minutes")]);
  const [sessionBit, setSessionBit] = useState();
  const [showSetAutoSequencingConfig, setShowSetAutoSequencingConfig] =
    useState(false);
  const [cancellationPolicies, setCancellationPolicies] = useState({
    isLoading: false,
    data: [],
  });

  const loadCancellationPolicies = async () => {
    const result = await dispatch(getAllCancellationPolicies());
    if (result?.status === "success") {
      setCancellationPolicies({
        isLoading: false,
        data: result.data,
      });
    }
  };

  const loadConfigurationByKey = async (ckey = "") => {
    const result = await dispatch(getConfigByKey(ckey));
    if (result?.status === "success") {
      if (ckey == "BookingSessionBit") {
        setSessionBit(result.data.value);
      } else {
        form.setFieldsValue({ time: parseInt(result.data.value) });
      }
    }
  };

  useEffect(() => {
    if (refreshPolicies) {
      loadCancellationPolicies();
      setRefreshPolicies(false);
    }
  }, [refreshPolicies]);

  useEffect(() => {
    loadConfigurationByKey("BookingSessionBit");
    loadConfigurationByKey("BookingSessionTimeout");
  }, []);

  const timeChangeHandle = (key, value) => {
    dispatch(updateConfigByKey(key, value)).then((result) => {
      if (result?.status === "success") {
        // loadData();
      }
    });
  };

  const MSwitch = React.useMemo(() => {
    return (
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        onChange={(checked) => {
          timeChangeHandle("BookingSessionBit", checked ? 1 : 0);
          setSessionBit(checked ? 1 : 0);
        }}
        checked={sessionBit == "1" ? true : false}
      />
    );
  }, [sessionBit]);

  return (
    <div className="miscellanousSettings">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
              // marginBottom: 4,
            }}
          >
            Session Timeout for Booking
          </Title>
          {/* {List} */}

          <Text
            style={{
              fontSize: 14,
              color: "#3C475F",
            }}
          >
            Turn on or off session timeout.
          </Text>
        </Col>
        <Col xs={24} md={18}>
          {MSwitch}
        </Col>

        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
              paddingTop: 16,
            }}
          >
            Select Time
          </Title>
        </Col>

        <Col xs={24} md={18}>
          <Form
            form={form}
            initialValues={{}}
            layout="vertical"
            requiredMark={false}
            onFinish={(values) => {}}
          >
            <FormSelect
              required
              variant="underlined"
              name={"time"}
              renderLabel="label"
              renderValue={"value"}
              formItemStyles={{
                marginBottom: 0,
                maxWidth: 400,
              }}
              // options={facilities ?? []}
              options={options}
              placeholder={"Select Time"}
              emptyMessage="No Time Available"
              onChange={(value) => {
                timeChangeHandle("BookingSessionTimeout", value);
              }}
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            />
          </Form>
        </Col>

        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
              paddingTop: 16,
            }}
          >
            Gift Cards Configuration
          </Title>
        </Col>
        <Col
          xs={24}
          md={18}
          style={{
            paddingTop: 16,
          }}
        >
          <Link
            onClick={() => {
              setShowGiftCardModal(true);
            }}
          >
            Update
          </Link>
        </Col>
        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
              paddingTop: 16,
            }}
          >
            Promo Gift Cards Configuration
          </Title>
        </Col>
        <Col
          xs={24}
          md={18}
          style={{
            paddingTop: 16,
          }}
        >
          <Link
            onClick={() => {
              setShowPromoGiftCardModal(true);
            }}
          >
            Update
          </Link>
        </Col>
        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
              paddingTop: 16,
            }}
          >
            Automated Scheduling Configuration
          </Title>
        </Col>
        <Col
          xs={24}
          md={6}
          style={{
            paddingTop: 16,
          }}
        >
          <Link
            onClick={() => {
              setShowSetAutoSequencingConfig(true);
            }}
          >
            {" "}
            Update
          </Link>
        </Col>

        <Col xs={24}>
          <Divider style={{ backgroundColor: "#B8BCC6", margin: "8px 0px" }} />
        </Col>

        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Cancellation Policy
          </Title>
        </Col>
        <Col xs={24} md={18}>
          <CancellationPolicy
            className="miscCancellationPolicy"
            cancellationPolicies={cancellationPolicies}
            setRefreshPolicies={setRefreshPolicies}
          />
        </Col>

        <Col xs={24}>
          <Divider style={{ backgroundColor: "#B8BCC6", margin: "8px 0px" }} />
        </Col>
        <Col xs={24} md={6}>
          <Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Good Faith Exam Policy
          </Title>
        </Col>
        <Col xs={24} md={18}>
          <GoodFaith />
        </Col>
      </Row>
      <GiftCardEditModal
        visible={showGiftCardModal}
        onCancel={() => setShowGiftCardModal(false)}
        onSave={() => {
          setShowGiftCardModal(false);
        }}
      />
      <PromoGiftCardEditModal
        visible={showPromoGiftCardModal}
        onCancel={() => setShowPromoGiftCardModal(false)}
        onSave={() => {
          setShowPromoGiftCardModal(false);
        }}
      />
      <ConfigAutoSequencingModal
        visible={showSetAutoSequencingConfig}
        onCancel={() => setShowSetAutoSequencingConfig(false)}
        onSave={() => {
          setShowSetAutoSequencingConfig(false);
        }}
      />
      {/* <GoodFaithExamPolicyModal
        visible={showGoodFaithExamPolicyModal}
        onCancel={() => setShowGoodFaithExamPolicyModal(false)}
      /> */}
    </div>
  );
}
