import { Button, Modal, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { FormType } from "utils/enums";
import styles from "../Charts.module.scss";
import { getFormByType, reopenForm } from "services/forms.service";
import { updateGoodFaithPhysician } from "services/intakeForm.service";
import moment from "moment";
import { Typography } from "antd";

const { Text } = Typography;

const GoodFaithCard = ({
  form,
  filteredStaff,
  getData,
  onClick,
  isSmallDevice,
  canOpenGFE,
}) => {
  const dispatch = useDispatch();
  const [firstPhysicianStaffId, setFirstPhysicianStaffId] = useState(
    form?.firstPhysicianStaffId
  );
  const [secondPhysicianStaffId, setSecondPhysicianStaffId] = useState(
    form?.secondPhysicianStaffId
  );

  useEffect(() => {
    setFirstPhysicianStaffId(form?.firstPhysicianStaffId);
    setSecondPhysicianStaffId(form?.secondPhysicianStaffId);
  }, [form?.firstPhysicianStaffId, form?.secondPhysicianStaffId]);

  const filteredStaffForNP1 = useMemo(() => {
    return filteredStaff.filter(
      (staff) =>
        staff.value === form?.firstPhysicianStaffId || staff.isActive === true
    );
  }, [filteredStaff, form?.firstPhysicianStaffId]);

  const filteredStaffForNP2 = useMemo(() => {
    return filteredStaff.filter(
      (staff) =>
        staff.value === form?.secondPhysicianStaffId || staff.isActive === true
    );
  }, [filteredStaff, form?.secondPhysicianStaffId]);

  const [valuesJSON, isNP1SignedOff, isNP2SignedOff] = useMemo(() => {
    const emptyResult = [{}, false, false];
    if (form?.valueJSON) {
      try {
        const json = JSON.parse(form?.valueJSON || "{}");
        const isNP1SignedOff =
          !!json?.np1Signature ||
          !!json?.customerSignature ||
          !!json?.nurseSignature;
        const isNP2SignedOff = !!json?.np2Signature;
        return [json, isNP1SignedOff, isNP2SignedOff];
      } catch (e) {
        return emptyResult;
      }
    } else return emptyResult;
  }, [form]);

  const hasGoodFaithForm = !!form?.formId;
  const canCreateGoodFaithForm = form?.canFillGoodFaithForm; // TODO: Guest > Form
  const isReopened = form?.isReopened;

  function handlePhysicanChange(value, order, revertStateUpdate) {
    // check if the GoodFaith Form Exists
    // If Good Faith Form Doesn't Exists
    if (!hasGoodFaithForm) {
      dispatch(getFormByType(FormType.goodFaithExam, true))
        .then((res) => {
          if (res?.status === "success") {
            const data = res?.data;
            const {
              designJSON,
              latestVersionId,
              formValueId,
              formType,
              formId,
            } = data ?? {};
            dispatch(
              updateGoodFaithPhysician(
                form?.customerId,
                -1,
                value,
                order,
                formId,
                latestVersionId
              )
            )
              .then((res) => {
                if (res?.status !== "success") {
                  revertStateUpdate?.();
                } else {
                  // getData();
                }
              })
              .catch(() => {
                revertStateUpdate?.();
              });
          } else {
            revertStateUpdate?.();
            // getData();
          }
        })
        .catch(() => {});
      // If Good Faith Form Exists
    } else {
      dispatch(
        updateGoodFaithPhysician(
          form?.customerId,
          form?.formValueId,
          value,
          order
        )
      )
        .then((res) => {
          if (res?.status !== "success") {
            revertStateUpdate?.();
          } else {
            getData();
          }
        })
        .catch(() => {
          revertStateUpdate?.();
        });
    }
  }

  function renderFormStatus() {
    const {
      goodFaithFormId,
      goodFaithSubmittedOn,
      isGoodFaithExpired,
      isGoodFaithOutdated,
    } = form;

    if (hasGoodFaithForm === false) {
      return <></>;
    } else if (isGoodFaithExpired) {
      return (
        <p className={styles.boldFont} style={{ color: "#ff4d4f" }}>
          Expired
        </p>
      );
    } else if (isGoodFaithOutdated) {
      return (
        <p className={styles.boldFont} style={{ color: "#ff4d4f" }}>
          Outdated
        </p>
      );
    }
    // else if (guest?.isGoodFaithSubmitted) {
    //   return (
    //     <p className={styles.boldFont} style={{ color: "#27CA2D" }}>
    //       Submitted
    //     </p>
    //   );
    // }
    else {
      return <></>;
    }
  }

  function renderSignatureStatus(signedOff) {
    if (signedOff) {
      return (
        <p
          className={styles.boldFont}
          style={{
            color: "#27CA2D",
          }}
        >
          Signed Off
        </p>
      );
    }
    return (
      <p
        className={styles.boldFont}
        style={{
          color: "#8E94AA",
        }}
      >
        Pending
      </p>
    );
  }

  function renderSection2Content() {
    if (!canCreateGoodFaithForm) {
      return (
        <div className={styles.noGFEContainer}>
          <p className={styles.normalFont}>No Good Faith Form</p>

          <div className={styles.noGFETextContainer}>
            <ExclamationCircleOutlined className={styles.exclaimationIcon} />
            <span className={styles.normalFont}>
              No Orders to create Good Faith Form
            </span>
          </div>
        </div>
      );
    }
    return (
      <>
        <div>
          <p className={styles.normalFont}>NP/Physician #1</p>
          <Select
            disabled={isNP1SignedOff && !isReopened}
            value={firstPhysicianStaffId}
            onChange={(value) => {
              setFirstPhysicianStaffId(value);
              handlePhysicanChange(value, 1, () => {
                setFirstPhysicianStaffId(form.firstPhysicianStaffId);
              });
            }}
            className={["home_select", styles.selector]}
            // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
            placeholder={"Select"}
            showSearch
            style={{
              width: 200,
            }}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={filteredStaffForNP1}
          />
        </div>
        <div>
          <p className={styles.boldFont}>Status</p>
          <div className={styles.signaturesTextContainer}>
            {renderSignatureStatus(isNP1SignedOff)}
            <p className={styles.signatureText}>
              {form.firstPhysicianSignOffDate &&
                moment
                  .utc(form.firstPhysicianSignOffDate)
                  .local()
                  .format("ddd Do MMM YYYY hh:mm a")}
            </p>
          </div>
        </div>
      </>
    );
  }

  function renderSection3Content() {
    if (!canCreateGoodFaithForm) {
      return <></>;
    }
    return (
      <>
        <div>
          <p className={styles.normalFont}>NP/Physician #2</p>
          <Select
            disabled={isNP2SignedOff && !isReopened}
            value={secondPhysicianStaffId}
            onChange={(value) => {
              setSecondPhysicianStaffId(value);
              handlePhysicanChange(value, 2, () => {
                secondPhysicianStaffId(form.secondPhysicianStaffId);
              });
            }}
            className={["home_select", styles.selector]}
            // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
            placeholder={"Select"}
            showSearch
            style={{
              width: 200,
            }}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={filteredStaffForNP2}
          />
        </div>
        <div className={styles.flexJustifyBetweenAlignEnd}>
          <div>
            <p className={styles.boldFont}>Status</p>
            <div className={styles.signaturesTextContainer}>
              {renderSignatureStatus(isNP2SignedOff)}

              <p className={styles.signatureText}>
                {form.secondPhysicianSignOffDate &&
                  moment
                    .utc(form.physicianSignedOffDate)
                    .local()
                    .format("ddd Do MMM YYYY hh:mm a")}
              </p>
            </div>
          </div>
          <div>
            <div className={styles.badge}></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div key={form.customerId} className={styles.card}>
      <div
        className={styles.section1}
        style={{
          cursor: canCreateGoodFaithForm ? "pointer" : "default",
        }}
        onClick={() => {
          if (canCreateGoodFaithForm) onClick();
        }}
      >
        <p className={styles.largeFont}>{form.customerName}</p>
        <p className={styles.normalFont}>Good Faith Form</p>
        {renderFormStatus()}
      </div>

      <div
        className={styles.section2}
        style={{
          borderRight:
            form?.requiredSecondSignature && !isSmallDevice
              ? "2px solid #e5e8ee"
              : "none",
          borderBottom:
            form?.requiredSecondSignature && isSmallDevice
              ? "2px solid #e5e8ee"
              : "none",
        }}
      >
        {renderSection2Content()}
      </div>
      <div
        className={styles.section3}
        style={{
          padding: !form?.requiredSecondSignature
            ? "0px 16px"
            : isSmallDevice
            ? "16px 0px"
            : "0px 16px",
        }}
      >
        <div>{form?.requiredSecondSignature && renderSection3Content()}</div>
      </div>
      {
        isReopened && <div>
          <Text className={styles.smallFont} type="warning">Reopened</Text>
          </div>
      }
      {form?.isGoodFaithFormSubmitted && canOpenGFE && (
        <div className={styles.controls}>
          <Button
            type="link"
            onClick={() => {
              Modal.confirm({
                content: "Are you sure you want to reopen this form?",
                title: "Reopen Good Faith Form",
                icon: <ExclamationCircleOutlined />,
                onOk: () => { 
                  dispatch(
                    reopenForm(form?.formValueId, form?.customerId)
                  ).then((res) => {
                    if (res?.status === "success") {
                      getData();
                    }
                  });
                },
                maskClosable: true,
              });
            }}
          >
            Reopen
          </Button>
          <div className={styles.checkmark}>
            <CheckOutlined
              style={{
                color: "#fff",
                fontSize: 16,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GoodFaithCard;
