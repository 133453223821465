import React, { useMemo, useState } from "react";
import { Space, Typography, Button as AntdButton, Grid, Spin } from "antd";

import GiftCardsList from "./GiftCardsList";
import GiftCardModal from "./GiftCardModal";

import styles from "./GiftCardRow.module.scss";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export default function GiftCardRow({
  view = "expanded", // "expanded" | "collapsed"

  customerId,
  disabled,
  onSave,
  onChange,
  giftCards = [],
  loading,
  customer,
  paymentMethod,
  refreshGiftCards,
  refreshCredits,
  customerWallets,
  isCustomerWalletsLoading,
}) {
  const screens = useBreakpoint();

  const [showAdd, setShowAdd] = useState(false);
  const [showViewAll, setShowViewAll] = useState(false);
  // const [showRedeem, setShowRedeem] = useState(false);

  const credit = useMemo(() => {
    return customerWallets
      ?.filter((wallet) => wallet.isGeneral === true)
      ?.reduce((acc, wallet) => {
        return acc + (wallet?.balance ?? 0);
      }, 0);
  }, [customerWallets]);

  const customerWalletsBalance = useMemo(() => {
    if (customerWallets && customerWallets.length > 0) {
      return customerWallets
        ?.filter((wallet) => wallet.isGeneral !== true)
        ?.reduce((acc, wallet) => {
          return acc + (wallet?.balance ?? 0);
        }, 0);
    }
    return 0;
  }, [customerWallets, isCustomerWalletsLoading]);

  return (
    <div
      className={styles.giftCardsRow}
      style={{
        flexDirection: !screens.md ? "column" : "row",
        alignItems: !screens.md ? "center" : "flex-start",
      }}
    >
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        {view !== "concise" && (
          <Title level={4} style={{ fontSize: 17, color: "#000", margin: 0 }}>
            Gift Cards
          </Title>
        )}

        {customerId && (
          <>
            {loading || isCustomerWalletsLoading ? (
              <Spin style={{ marginLeft: 28 }} />
            ) : (
              <div className={styles.balanceContainer}>
                <Text style={{ marginRight: 8 }}>
                  Wallet Balance: ${credit ?? 0}
                </Text>
                <Text>Promotional Balance: ${customerWalletsBalance ?? 0}</Text>
              </div>
            )}
          </>
        )}
      </Space>
      {customerId && (
        <Space direction="horizontal" size={2}>
          {giftCards?.length > 0 && (
            <AntdButton
              type="text"
              style={{ paddingTop: 0 }}
              //   disabled={disabled}
              //   onClick={() => {
              //     setShowChangeModal(true);
              //   }}
              onClick={() => {
                setShowViewAll(true);
              }}
            >
              View All
            </AntdButton>
          )}
          {/* <AntdButton
            type="text"
            //   disabled={disabled}
            onClick={() => {
              setShowRedeem(true);
            }}
          >
            Redeem
          </AntdButton> */}
          <AntdButton
            type="text"
            style={{ paddingTop: 0 }}
            //   disabled={disabled}
            onClick={() => {
              setShowAdd(true);
            }}
          >
            Add
          </AntdButton>
        </Space>
      )}
      {/* Drawers Start */}
      <GiftCardsList
        paymentMethod={paymentMethod}
        customer={customer}
        onView={(giftCard) => setShowAdd(giftCard)}
        visible={showViewAll}
        onClose={() => {
          setShowViewAll(false);
        }}
        loading={loading}
        giftCards={giftCards}
        refreshGiftCards={refreshGiftCards}
        refreshCredits={refreshCredits}
      />
      <GiftCardModal
        paymentMethod={paymentMethod}
        customer={customer}
        visible={!!showAdd}
        data={showAdd}
        onCancel={() => setShowAdd()}
        onSave={() => {
          setShowAdd();
          refreshGiftCards();
          refreshCredits();
        }}
      />
      {/* <GiftCardRedeemModal
        customer={customer}
        visible={showRedeem}
        onCancel={() => setShowRedeem()}
        onSave={() => {
          setShowRedeem();
          refreshCredits();
        }}
      /> */}

      {/* Drawers End */}
    </div>
  );
}
