/**
 * <Toolbar />
 */

import React from "react";
import { injectIntl } from "react-intl";
import ToolbarItem from "./toolbar-draggable-item";
import ToolbarGroupItem from "./toolbar-group-item";

import ID from "./UUID";
import store from "./stores/store";
import { groupBy } from "./functions";
import myxss from "./form-elements/myxss";
import { Col, Row, Typography } from "antd";
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormDatePicker,
  FormInput,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import ImageAnnotator from "components/ImageAnnotator";

import NeuromodularImage from "assets/images/neuromodular_form.png";
import AestheticImage from "assets/images/aestheticdermal_form.png";

// function isDefaultItem(item) {
//   const keys = Object.keys(item);
//   return keys.filter(x => x !== 'element' && x !== 'key' && x !== 'group_name').length === 0;
// }

const { Title } = Typography;

function buildItems(items, defaultItems) {
  if (!items) {
    return defaultItems;
  }
  return items.map((x) => {
    let found = defaultItems.find(
      (y) => x.element === y.element && y.key === x.key
    );
    if (!found) {
      found = defaultItems.find(
        (y) => (x.element || x.key) === (y.element || y.key)
      );
    }
    if (found) {
      if (x.inherited !== false) {
        found = { ...found, ...x };
      } else if (x.group_name) {
        found.group_name = x.group_name;
      }
    }
    return found || x;
  });
}

function buildGroupItems(allItems) {
  const items = allItems.filter((x) => !x.group_name);
  const gItems = allItems.filter((x) => !!x.group_name);
  const grouped = groupBy(gItems, (x) => x.group_name);
  const groupKeys = gItems
    .map((x) => x.group_name)
    .filter((v, i, self) => self.indexOf(v) === i);
  return { items, grouped, groupKeys };
}

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;
    const items = buildItems(props.items, this._defaultItems(intl));
    this.state = {
      items,
    };
    this.create = this.create.bind(this);
  }

  componentDidMount() {
    store.subscribe((state) => this.setState({ store: state }));
  }

  static _defaultItemOptions(element, intl) {
    switch (element) {
      case "Dropdown":
        return [
          {
            value: "place_holder_option_1",
            text: intl.formatMessage({ id: "place-holder-option-1" }),
            key: `dropdown_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_2",
            text: intl.formatMessage({ id: "place-holder-option-2" }),
            key: `dropdown_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_3",
            text: intl.formatMessage({ id: "place-holder-option-3" }),
            key: `dropdown_option_${ID.uuid()}`,
          },
        ];
      case "Tags":
        return [
          {
            value: "place_holder_tag_1",
            text: intl.formatMessage({ id: "place-holder-tag-1" }),
            key: `tags_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_tag_2",
            text: intl.formatMessage({ id: "place-holder-tag-2" }),
            key: `tags_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_tag_3",
            text: intl.formatMessage({ id: "place-holder-tag-3" }),
            key: `tags_option_${ID.uuid()}`,
          },
        ];
      case "Checkboxes":
        return [
          {
            value: "place_holder_option_1",
            text: intl.formatMessage({ id: "place-holder-option-1" }),
            key: `checkboxes_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_2",
            text: intl.formatMessage({ id: "place-holder-option-2" }),
            key: `checkboxes_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_3",
            text: intl.formatMessage({ id: "place-holder-option-3" }),
            key: `checkboxes_option_${ID.uuid()}`,
          },
        ];
      case "RadioButtons":
        return [
          {
            value: "place_holder_option_1",
            text: intl.formatMessage({ id: "place-holder-option-1" }),
            key: `radiobuttons_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_2",
            text: intl.formatMessage({ id: "place-holder-option-2" }),
            key: `radiobuttons_option_${ID.uuid()}`,
          },
          {
            value: "place_holder_option_3",
            text: intl.formatMessage({ id: "place-holder-option-3" }),
            key: `radiobuttons_option_${ID.uuid()}`,
          },
        ];
      default:
        return [];
    }
  }

  _defaultItems(intl) {
    return [
      {
        key: "Header",
        name: intl.formatMessage({ id: "header-text" }),
        icon: "fas fa-heading",
        static: true,
        content: intl.formatMessage({ id: "place-holder-text" }),
      },
      // {
      //   key: "Label",
      //   name: intl.formatMessage({ id: "label" }),
      //   static: true,
      //   icon: "fas fa-font",
      //   content: intl.formatMessage({ id: "place-holder-text" }),
      // },
      {
        key: "Paragraph",
        name: intl.formatMessage({ id: "paragraph" }),
        static: true,
        icon: "fas fa-paragraph",
        content: intl.formatMessage({ id: "place-holder-text" }),
      },
      {
        key: "LineBreak",
        name: intl.formatMessage({ id: "line-break" }),
        static: true,
        icon: "fas fa-arrows-alt-h",
      },
      {
        key: "Dropdown",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "dropdown" }),
        icon: "far fa-caret-square-down",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "dropdown_",
        options: [],
      },
      // {
      //   key: "Tags",
      //   canHaveAnswer: true,
      //   name: intl.formatMessage({ id: "tags" }),
      //   icon: "fas fa-tags",
      //   label: intl.formatMessage({ id: "place-holder-label" }),
      //   field_name: "tags_",
      //   options: [],
      // },
      {
        key: "Checkboxes",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "checkboxes" }),
        icon: "far fa-check-square",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "checkboxes_",
        options: [],
      },
      {
        key: "RadioButtons",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "multiple-choice" }),
        icon: "far fa-dot-circle",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "radiobuttons_",
        options: [],
      },
      {
        key: "TextInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "text-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-font",
        field_name: "text_input_",
      },
      {
        key: "EmailInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "email-input" }),
        label: intl.formatMessage({ id: "place-holder-email" }),
        icon: "fas fa-envelope",
        field_name: "email_input_",
      },
      {
        key: "NumberInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "number-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-plus",
        field_name: "number_input_",
      },
      {
        key: "PhoneNumber",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "phone-input" }),
        label: intl.formatMessage({ id: "place-holder-phone-number" }),
        icon: "fas fa-phone",
        field_name: "phone_input_",
      },
      {
        key: "TextArea",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "multi-line-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-text-height",
        field_name: "text_area_",
      },
      // {
      //   key: "CheckboxRow",
      //   canHaveAnswer: false,
      //   name: "Checkbox Row",
      //   label: "",
      //   icon: "fas fa-columns",
      //   field_name: "checkbox_row_",
      //   col_count: 2,
      // },
      {
        key: "TwoColumnRow",
        canHaveAnswer: false,
        name: intl.formatMessage({ id: "two-columns-row" }),
        label: "",
        icon: "fas fa-columns",
        field_name: "two_col_row_",
      },
      {
        key: "ThreeColumnRow",
        canHaveAnswer: false,
        name: intl.formatMessage({ id: "three-columns-row" }),
        label: "",
        icon: "fas fa-columns",
        field_name: "three_col_row_",
      },
      {
        key: "FourColumnRow",
        element: "MultiColumnRow",
        canHaveAnswer: false,
        name: intl.formatMessage({ id: "four-columns-row" }),
        label: "",
        icon: "fas fa-columns",
        field_name: "four_col_row_",
        col_count: 4,
        class_name: "col-md-3",
      },
      // {
      //   key: "FiveColumnRow",
      //   element: "MultiColumnRow",
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: "five-columns-row" }),
      //   label: "",
      //   icon: "fas fa-columns",
      //   field_name: "five_col_row_",
      //   col_count: 5,
      //   class_name: "col",
      // },
      // {
      //   key: "SixColumnRow",
      //   element: "MultiColumnRow",
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: "six-columns-row" }),
      //   label: "",
      //   icon: "fas fa-columns",
      //   field_name: "six_col_row_",
      //   col_count: 6,
      //   class_name: "col-md-2",
      // },
      // {
      //   key: "Image",
      //   name: intl.formatMessage({ id: "image" }),
      //   label: "",
      //   icon: "far fa-image",
      //   field_name: "image_",
      //   src: "",
      // },
      // {
      //   key: "Rating",
      //   canHaveAnswer: true,
      //   name: intl.formatMessage({ id: "rating" }),
      //   label: intl.formatMessage({ id: "place-holder-label" }),
      //   icon: "fas fa-star",
      //   field_name: "rating_",
      // },
      {
        key: "CheckboxInputTemplate",
        element: "CustomElement",
        type: "custom",
        canHaveAnswer: false,
        name: "Checkbox Input Template",
        label: "Checkbox Input Template",
        icon: "fas fa-columns",
        field_name: "checkbox_input_template_",
        component: (item) => {
          return (
            <Row wrap={false}>
              <Col>
                <FormCheckbox style={{ marginRight: 12 }} />
              </Col>
              <Col flex="auto">
                <FormInput
                  formItemClassName="normalLabel"
                  variant="underlined"
                  rows={6}
                  formItemStyles={{ width: "100%" }}
                  label={`*${item?.data?.label}`}
                  placeholder="Enter here..."
                />
              </Col>
            </Row>
          );
        },
      },
      {
        key: "DosageTemplate",
        element: "CustomElement",
        type: "custom",
        canHaveAnswer: false,
        name: "Dosage Template",
        label: "Dosage Template",
        icon: "fas fa-columns",
        field_name: "dosage_template_",
        component: (item) => {
          const { bold_all_child_labels } = item?.data ?? {};
          return (
            <>
              <Row wrap={false}>
                <Col>
                  <FormCheckbox style={{ marginRight: 12, marginBottom: 0 }} />
                </Col>
                <Col flex="auto">
                  <Title
                    className="templateLabel"
                    level={4}
                    style={{
                      fontSize: 16,
                      paddingTop: 13,
                      color: "#000",
                      margin: 0,
                      lineHeight: 1,
                      fontWeight: 400,
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: myxss.process(item?.data?.label),
                      }}
                    ></span>
                  </Title>
                </Col>
              </Row>
              <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
                <Col xs={24} md={6}>
                  <FormDatePicker
                    formItemClassName={
                      bold_all_child_labels ? "" : "normalLabel"
                    }
                    styles={{
                      width: "100%",
                      fontSize: 16,
                      height: 49,
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: 4,
                    }}
                    label="Expiration Date"
                    // disabled={chart[0]?.staffSignature}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <FormInput
                    formItemClassName={
                      bold_all_child_labels ? "" : "normalLabel"
                    }
                    // required={formChecked.boostNS500Checked}
                    label="Lot Number"
                    placeholder="Lot Number"
                    // disabled={chart[0]?.staffSignature}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <FormInput
                    formItemClassName={
                      bold_all_child_labels ? "" : "normalLabel"
                    }
                    // required={formChecked.boostNS500Checked}
                    label="Route"
                    placeholder="Route"
                    // disabled={chart[0]?.staffSignature}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <FormInputNumber
                    formItemClassName={
                      bold_all_child_labels ? "" : "normalLabel"
                    }
                    // variant="underlined"
                    // disabled={chart[0]?.staffSignature}
                    // required={formChecked.boostNS500Checked}
                    label="Dose (mL)"
                    placeholder="Dose (mL)"
                  />
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        key: "Contraindications",
        element: "CustomElement",
        type: "custom",
        component: (item) => {
          return (
            <FormCheckboxGroup
              formItemClassName="normalLabel"
              disabled={item.data.disabled}
              name={item.data.field_name}
              required={item.data.required}
              // disabled={iterator.disabled}}
              // name="contraindications"
              inline={item.data.inline === true}
              label={item.data.label}
              options={item.data.contraindication_options?.map?.(
                (option, index) => ({
                  label: option.contraindicationName,
                  value: option.contraindicationId,
                })
              )}
              // formItemStyles={{ marginBottom: 12 }}
            />
          );
        },
        name: "Contraindications",
        icon: "fas fa-pen-square",
        label: "Contraindications",
        field_name: "contraindications",
      },
      {
        key: "ImageWidget",
        element: "CustomElement",
        type: "custom",
        component: (item) => {
          return <ImageAnnotator image={NeuromodularImage} />;
        },
        name: "Neurotoxin Image",
        icon: "fas fa-pen-square",
        label: "Neurotoxin Image",
        field_name: "imageWidget",
      },
      {
        key: "ImageWidget2",
        element: "CustomElement",
        type: "custom",
        component: (item) => {
          return <ImageAnnotator image={AestheticImage} />;
        },
        name: "Dermal Filler",
        icon: "fas fa-pen-square",
        label: "Dermal Filler",
        field_name: "imageWidget2",
      },
      {
        key: "GuestSignature",
        element: "CustomElement",
        type: "custom",
        component: () => <div></div>,
        name: intl.formatMessage({ id: "guest_signature" }),
        icon: "fas fa-pen-square",
        label: intl.formatMessage({ id: "guest_signature" }),
        field_name: "guest_signature_",
      },
      {
        key: "NurseSignature",
        element: "CustomElement",
        type: "custom",
        component: () => <div></div>,
        name: intl.formatMessage({ id: "nurse_signature" }),
        icon: "fas fa-pen-square",
        label: intl.formatMessage({ id: "nurse_signature" }),
        field_name: "nurse_signature_",
      },
      {
        key: "StaffNurseSignature",
        element: "CustomElement",
        type: "custom",
        component: () => <div></div>,
        name: intl.formatMessage({ id: "staff_nurse_signature" }),
        icon: "fas fa-pen-square",
        label: intl.formatMessage({ id: "staff_nurse_signature" }),
        field_name: "staff_nurse_signature_",
      },
      {
        key: "GoodFaithSignature",
        element: "CustomElement",
        type: "custom",
        component: () => <div></div>,
        name: intl.formatMessage({ id: "goodfaith_signature" }),
        icon: "fas fa-pen-square",
        label: intl.formatMessage({ id: "goodfaith_signature" }),
        field_name: "goodfaith_signature",
      },
      // {
      //   key: "HyperLink",
      //   name: intl.formatMessage({ id: "website" }),
      //   icon: "fas fa-link",
      //   static: true,
      //   content: intl.formatMessage({ id: "place-holder-website-link" }),
      //   href: "http://www.example.com",
      // },
      // {
      //   key: "Download",
      //   name: intl.formatMessage({ id: "file-attachment" }),
      //   icon: "fas fa-file",
      //   static: true,
      //   content: intl.formatMessage({ id: "place-holder-file-name" }),
      //   field_name: "download_",
      //   file_path: "",
      //   _href: "",
      // },
      {
        key: "Range",
        name: intl.formatMessage({ id: "range" }),
        icon: "fas fa-sliders-h",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "range_",
        step: 1,
        default_value: 3,
        min_value: 1,
        max_value: 5,
        min_label: intl.formatMessage({ id: "easy" }),
        max_label: intl.formatMessage({ id: "difficult" }),
      },
      // {
      //   key: "Camera",
      //   name: intl.formatMessage({ id: "camera" }),
      //   icon: "fas fa-camera",
      //   label: intl.formatMessage({ id: "place-holder-label" }),
      //   field_name: "camera_",
      // },
      // {
      //   key: "FileUpload",
      //   name: intl.formatMessage({ id: "file-upload" }),
      //   icon: "fas fa-file",
      //   label: intl.formatMessage({ id: "place-holder-label" }),
      //   field_name: "file_upload_",
      // },
    ];
  }

  create(item) {
    const { intl } = this.props;

    const elementOptions = {
      id: ID.uuid(),
      element: item.element || item.key,
      text: item.name,
      group_name: item.group_name,
      static: item.static,
      required: false,
      showDescription: item.showDescription,
    };

    if (this.props.showDescription === true && !item.static) {
      elementOptions.showDescription = true;
    }

    if (item.key === "Contraindications") {
      const filteredContraIndications = this.props.contraindications?.filter(
        (it) => it?.isActive
      );
      elementOptions.contraindications =
        filteredContraIndications?.map((it) => it?.contraindicationId) ?? [];
      elementOptions.contraindication_options = filteredContraIndications ?? [];
    }

    if (item.type === "custom") {
      elementOptions.key = item.key;
      elementOptions.custom = true;
      elementOptions.forwardRef = item.forwardRef;
      elementOptions.bare = item.bare;
      elementOptions.props = item.props;
      elementOptions.component = item.component || null;
      elementOptions.custom_options = item.custom_options || [];
    }

    if (item.static) {
      elementOptions.bold = false;
      elementOptions.italic = false;
    }

    if (item.canHaveAnswer) {
      elementOptions.canHaveAnswer = item.canHaveAnswer;
    }

    if (item.canReadOnly) {
      elementOptions.readOnly = false;
    }

    if (item.canDefaultToday) {
      elementOptions.defaultToday = false;
    }

    if (item.content) {
      elementOptions.content = item.content;
    }

    if (item.href) {
      elementOptions.href = item.href;
    }

    if (item.inherited !== undefined) {
      elementOptions.inherited = item.inherited;
    }

    elementOptions.canHavePageBreakBefore =
      item.canHavePageBreakBefore !== false;
    elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
    elementOptions.canHaveDisplayHorizontal =
      item.canHaveDisplayHorizontal !== false;
    if (elementOptions.canHaveDisplayHorizontal) {
      elementOptions.inline = true;
    }
    elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
    elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
    elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

    if (item.class_name) {
      elementOptions.class_name = item.class_name;
    }

    if (item.key === "Image") {
      elementOptions.src = item.src;
    }

    if (item.key === "DatePicker") {
      elementOptions.dateFormat = item.dateFormat;
      elementOptions.timeFormat = item.timeFormat;
      elementOptions.showTimeSelect = item.showTimeSelect;
      elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
      elementOptions.showTimeInput = item.showTimeInput;
    }

    if (item.key === "Download") {
      elementOptions._href = item._href;
      elementOptions.file_path = item.file_path;
    }

    if (item.key === "Range") {
      elementOptions.step = item.step;
      elementOptions.default_value = item.default_value;
      elementOptions.min_value = item.min_value;
      elementOptions.max_value = item.max_value;
      elementOptions.min_label = item.min_label;
      elementOptions.max_label = item.max_label;
    }

    if (item.element === "MultiColumnRow") {
      elementOptions.col_count = item.col_count;
    }

    if (item.defaultValue) {
      elementOptions.defaultValue = item.defaultValue;
    }

    if (item.field_name && item.key !== "Contraindications") {
      elementOptions.field_name = item.field_name + ID.uuid();
    } else {
      elementOptions.field_name = item.field_name;
    }

    if (item.label) {
      elementOptions.label = item.label;
    }

    if (item.options) {
      if (item.options.length > 0) {
        elementOptions.options = item.options;
      } else {
        elementOptions.options = Toolbar._defaultItemOptions(
          elementOptions.element,
          intl
        );
      }
    }

    return elementOptions;
  }

  _onClick(item) {
    // ElementActions.createElement(this.create(item));
    store.dispatch("create", this.create(item));
  }

  renderItem = (item) => (
    <ToolbarItem
      data={item}
      key={item.key}
      onClick={this._onClick.bind(this, item)}
      onCreate={this.create}
    />
  );

  render() {
    const { items, grouped, groupKeys } = buildGroupItems(this.state.items);
    return (
      <div
        className="col-md-3 react-form-builder-toolbar"
        style={{
          overflow: "scroll",
          height: "calc(100vh - 86px - 19px)",
        }}
      >
        <h4>{this.props.intl.formatMessage({ id: "toolbox" })}</h4>
        <ul>
          {items.map(this.renderItem)}
          {groupKeys.map((k) => (
            <ToolbarGroupItem
              key={k}
              name={k}
              group={grouped.get(k)}
              renderItem={this.renderItem}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default injectIntl(Toolbar);
