import { useForm } from "antd/lib/form/Form";
import Modal from "components/Modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button as AntdButton, Col, Form, Row, Tag, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import {
  FormCheckbox,
  FormHiddenSubmitButton,
  FormInputArea,
  FormInputNumber,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { getConfigByKey, updateConfigByKey } from "services/misc.service";
import { update } from "lodash";
import { openNotificationWithIcon } from "utils/Notification";
import { GIFTCARD_CONFIG_KEY, PROMO_GIFTCARD_CONFIG_KEY } from "utils/constants";
import { getGiftCardDesign, getPromoGiftCardDesign } from "services/giftCards.service";

const { Title } = Typography;

export default function PromoGiftCardEditModal({ visible, onCancel, onSave }) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();

  const [data, setData] = useState();

  useEffect(() => {
    if (visible) {
      dispatch(getPromoGiftCardDesign(true)).then((res) => {
        if (res?.status === "success") {
          form.setFieldsValue({
            ...res?.data,
          });
        }
      });
    } else {
      setData();
    }
  }, [visible]);

  const handleSubmit = (values) => {
    const json = encodeURI(JSON.stringify(values));
    dispatch(updateConfigByKey(PROMO_GIFTCARD_CONFIG_KEY, json)).then((res) => {
      if (res?.status === "success") {
        onSave();
        openNotificationWithIcon(
          "success",
          "Update Successful",
          "Promo Gift Card onfiguration has been updated successfully"
        );
      }
    });
    // console.log("decodeURI json", JSON.parse(decodeURI(json)));
  };

  return (
    <Modal
      className="ant-modal-title-tradeGothic"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Update Gift Card Configuration"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton type="text" style={{ padding: 0 }}>
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => {
              submitBtnRef?.current?.click();
            }}
            //   disabled={!Boolean(setupIntent)}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log(errorFields);
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Title
              variant={5}
              style={{
                margin: 0,
                fontSize: 16,
                color: "#161F3A",
                marginBottom: 12,
              }}
            >
              Terms & Conditions
            </Title>
            <FormInputArea
              name={"notes"}
              variant="underlined"
              formItemStyles={{ width: "100%", margin: 0 }}
            />
          </Col>
        </Row>
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}
