import React, { useEffect } from "react";
import { FilterOutlined } from "@ant-design/icons";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { FormRadioGroup, FormSelect } from "components/FormItems/FlatFormItems";
import { useSelector } from "react-redux";
import { CalenderOperationType } from "utils/enums";
import { Form } from "antd";

export default function NPFilter({ selectedNP, onSelectedNPChange }) {
  const [showFilter, setShowFilter] = React.useState(false);

  const { wholeStaff } = useSelector((state) => ({
    wholeStaff: state.user.wholeStaff,
  }));

  const { facilities, isFetchingFacilities } = useSelector((state) => {
    return {
      facilities: state.facilities.allFacilities,
      isFetchingFacilities: state.facilities.isFetchingFacilities,
    };
  });

  const [form] = Form.useForm();
  const operationTypeId = Form.useWatch("operationTypeId", form);
  const facilityIds = Form.useWatch("facilityIds", form);

  const filteredFacilities = facilities?.filter((facility) => {
    if (operationTypeId === CalenderOperationType.All) {
      return true;
    }
    return facility?.operationTypeIds?.includes(operationTypeId);
  });

  const filteredStaff = wholeStaff?.filter((staff) => {
    return (
      (staff.roleName === "Nurse Practitioner" ||
        staff.roleName === "Physician") &&
      staff.facilityIds?.some((staffFacilityId) => {
        if (facilityIds?.length > 0) {
          return facilityIds?.some(
            (facilityId) => facilityId === staffFacilityId
          );
        } else
          return filteredFacilities?.some(
            (facility) => facility.facilityId === staffFacilityId
          );
      })
    );
  });

  useEffect(() => {
    if (showFilter) {
      form.setFieldsValue({
        operationTypeId:
          selectedNP?.operationTypeId ?? CalenderOperationType.All,
        facilityIds: selectedNP?.facilityIds ?? [],
        assignedTo: selectedNP?.assignedTo ?? [],
      });
    }
  }, [showFilter, selectedNP]);

  return (
    <>
      <button
        className={`calenderFilterBtn ${selectedNP ? "active" : ""}`}
        onClick={() => {
          setShowFilter(true);
        }}
      >
        <FilterOutlined style={{ fontSize: 18 }} />
      </button>
      <Modal
        title="Filters"
        open={showFilter}
        // onOk={() => {}}
        onCancel={() => {
          setShowFilter(false);
        }}
        footer={[]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            onSelectedNPChange(values);
            setShowFilter(false);
          }}
          initialValues={{
            operationTypeId: CalenderOperationType.All,
          }}
          defaultValue={{
            operationTypeId: CalenderOperationType.All,
          }}
        >
          <FormRadioGroup
            name="operationTypeId"
            label="Facility Type"
            //   className="boldRadio"
            //   name="isActive"
            //   label="How many days a week do you exercise?"
            style={{
              marginBottom: 0,
            }}
            options={[
              { label: "All", value: CalenderOperationType.All },
              {
                label: "Mobile",
                value: CalenderOperationType.Mobile,
              },
              {
                label: "Clinic",
                value: CalenderOperationType.Clinic,
              },
              {
                label: "Telehealth",
                value: CalenderOperationType.Telehealth,
              },
            ]}
            onChangeHandler={(value) => {
              form.setFieldsValue({
                facilityIds: [],
                assignedTo: [],
              });
            }}
          />
          <FormSelect
            name="facilityIds"
            formItemStyles={{
              width: "100%",
            }}
            //   value={selectedNP}
            onChange={(value) => {
              // onSelectedNPChange(value);
              // dispatch(setCurrentStaffIds(value));
              form.setFieldsValue({
                assignedTo: [],
              });
            }}
            // disabled={isAppointmentFormDisabled}
            variant="underlined"
            label="Facilities"
            placeholder="Facilties"
            // required={true}
            // loading={isFetchingAddons}
            options={filteredFacilities}
            renderValue="facilityId"
            renderLabel="facilityName"
            mode="multiple"
            optionLabelProp="label"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase?.()
                .includes?.(input.toLowerCase());
            }}
            allowClear={true}
          />

          <FormSelect
            name="assignedTo"
            formItemStyles={{
              width: "100%",
            }}
            // disabled={isAppointmentFormDisabled}
            variant="underlined"
            label="NP/Physician"
            placeholder="NP/Physician"
            // required={true}
            // loading={isFetchingAddons}
            options={filteredStaff}
            renderValue="staffId"
            renderLabel="name"
            mode="multiple"
            optionLabelProp="label"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase?.()
                .includes?.(input.toLowerCase());
            }}
            renderOption={(opt) => {
              return opt["name"];
            }}
            allowClear={true}
          />
          <div style={{ gap: 12, display: "flex" }}>
            <Button
              htmlType={"submit"}
              rounded={true}
              onClick={() => {
                // dispatch(setCurrentStaffIds([]));
                // onSelectedNPChange();
              }}
            >
              Submit
            </Button>
            <Button
              rounded={true}
              onClick={() => {
                // dispatch(setCurrentStaffIds([]));
                // onSelectedNPChange();
                form.resetFields();
              }}
            >
              Clear
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
