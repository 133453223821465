import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import NewBookingsDrawer from "components/NewBookingsDrawer/NewBookingsDrawer";
import { getAllPendingBookings } from "services/booking.service";
import moment from "moment";
import { getAllStaff } from "services/staffs.services";
import { openNotificationWithIcon } from "utils/Notification";
import { uniqBy } from "lodash";

import styles from "./NewBookingButton.module.scss";

const format = "YYYY-MM-DD";
function NewBookingButton(props) {
  const abortConRef = useRef();
  const [newBooking, setNewBooking] = useState(false);

  const { wholeStaff, allPendingBookings, allFacilities } = useSelector(
    (state) => {
      return {
        allFacilities: state.facilities.allFacilities,
        allPendingBookings: state.bookings.allPendingBookings,
        wholeStaff: state.user.wholeStaff,
      };
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPendingBookings());
    dispatch(getAllStaff());
    const getAllPendingBookingsInterval = setInterval(
      () => {
        if (abortConRef.current) abortConRef.current.abort();
        abortConRef.current = new AbortController();
        dispatch(getAllPendingBookings(false, abortConRef?.current?.signal));
      },
      20000 // 10 seconds
    );
    // const getAllStaffInterval = setInterval(
    //   () => dispatch(getAllStaff),
    //   10000 // 1 minute
    // );
    // dispatch(getAllPendingBookings());
    // dispatch(getAllStaff());

    return () => {
      clearInterval(getAllPendingBookingsInterval);
      // clearInterval(getAllStaffInterval);
    };
  }, []);

  const [groupedPendingBookings, totalGroupedPendingBookings] = useMemo(() => {
    const uniqPendingBookings = uniqBy(allPendingBookings, "orderId");
    const [groupedPendingBookings, totalPending] = uniqPendingBookings.reduce(
      ([acc, count], booking) => {
        const today = moment();
        const todayString = today.format("YYYY-MM-DD");
        const serviceDate = moment(booking?.serviceDate);
        const serviceDateString = serviceDate.format("YYYY-MM-DD");
        const today7 = moment().add(7, "days");
        const today7String = today7.format("YYYY-MM-DD");

        let groupName = "";
        // if it is today then

        if (todayString === serviceDateString) groupName = "Today";
        else if (serviceDate.isBefore(today)) {
          return [acc, count];
          groupName = "Past";
        }
        // else if it is in the same weekend as today
        else if (serviceDate.week() === today.week()) {
          groupName = serviceDate.format("dddd");
        } else {
          groupName = serviceDateString;
        }
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(booking);
        count += 1;
        return [acc, count];
      },
      [{}, 0]
    );

    // console.log(
    //   "groupedPendingBookingssss",
    //   Object.entries(groupedPendingBookings)
    // );
    let entries = Object.entries(groupedPendingBookings);
    entries.sort((a, b) => {
      let aKey = a[0];
      let bKey = b[0];

      let e = {
        Past: -10,
        Today: -9,
        Monday: -8,
        Tuesday: -7,
        Wednesday: -6,
        Thursday: -5,
        Friday: -4,
        Saturday: -3,
        Sunday: -2,
      };
      if (e[aKey] && e[bKey]) {
        return e[aKey] - e[bKey];
      } else if (e[aKey] && e[bKey] === undefined) {
        return -1;
      } else if (e[aKey] === undefined && e[bKey]) {
        return 1;
      } else {
        return moment(aKey, "YYYY-MM-DD") - moment(bKey, "YYYY-MM-DD");
      }
    });
    // console.log("entries", entries);
    // return groupedPendingBookings.sort((a, b) => {
    //   console.log("a, b", a, b);
    //   return 1;
    // });

    return [entries, totalPending];
  }, [allPendingBookings]);
  // console.log("groupedPendingBookings", groupedPendingBookings);
  return (
    <>
      <div
        className={styles.container}
        // style={}
        onClick={() => {
          setNewBooking(true);
        }}
      >
        <div className={styles.circle}>{totalGroupedPendingBookings}</div>
        <span
          style={{
            fontFamily: "tradeGothic",
            color: "#fff",
            fontWeight: 700,
          }}
        >
          New Bookings
        </span>
      </div>
      <NewBookingsDrawer
        visible={newBooking}
        onClose={() => setNewBooking(false)}
        groupedBookings={groupedPendingBookings}
        staff={wholeStaff}
        facilities={allFacilities}
      />
    </>
  );
}

NewBookingButton.propTypes = {};

export default NewBookingButton;
