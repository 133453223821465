import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import { Row, Col, Grid, Space, Button, Typography, Select, Modal } from "antd";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import ExclamationCircleFilled from "@ant-design/icons/ExclamationCircleFilled";

import AuditList from "./AuditList";
import { is } from "date-fns/locale";
import { FormType } from "utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { reopenForm } from "services/forms.service";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

function IntakeFormCard({
  isExpired,
  isLegacy,
  isOutdated,
  isAllowedToEdit,
  title,
  date,
  isFilled,
  type,
  modalVisibilityHander,
  user,
  // isReopened,
  handleGoodFaithReopen,
}) {
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;
  const dispatch = useDispatch();
  // const wholeStaff = useSelector((state) => state.user?.wholeStaff);
  // const allRoles = useSelector((state) => state.roles.allRoles);
  // const filteredStaff = useMemo(() => {
  //   return wholeStaff
  //     ?.filter?.((staff) => {
  //       const role = allRoles?.find?.((role) => role.roleId === staff?.roleId);
  //       if (
  //         role.permissions.find(
  //           (permission) => permission.permissionName === "GFE Signature"
  //         )
  //       )
  //         return staff.isActive;
  //       return false;
  //     })
  //     ?.map((staff) => ({
  //       value: staff.staffId,
  //       label: staff.firstName + " " + staff.lastName,
  //     }));
  // }, [wholeStaff, allRoles]);

  const [showAudit, setShowAudit] = useState(false);
  useEffect(() => {}, []);

  const Controls = (
    <>
    {/* {
      type === FormType.goodFaithExam && isReopened && <>Reopened</>
    } */}
      <Button
        type="text"
        onClick={() =>
          modalVisibilityHander({
            visible: true,
            isEdit: false,
          })
        }
      >
        View
      </Button>
      {type === FormType.goodFaithExam && isFilled && (
        <Button
          type="text"
          onClick={() => {
            handleGoodFaithReopen();
          }}
        >
          Reopen
        </Button>
      )}
      <Button type="text" onClick={() => setShowAudit(true)}>
        History
      </Button>
    </>
  );
  console.log("type", type);
  return (
    <>
      <Row className={`intakeFormCardContainer `}>
        <>
          <Col>
            <Title level={4} className="tradeGothic colorPrimary">
              {title}
            </Title>
          </Col>
          <Col flex="auto">
            <div className="informFormCardControls">
              {(isExpired || isLegacy || isOutdated || !isFilled) &&
                isAllowedToEdit && (
                  <div>
                    <Button
                      type="text"
                      onClick={() =>
                        modalVisibilityHander({
                          visible: true,
                          isEdit: true,
                        })
                      }
                    >
                      Edit
                    </Button>
                  </div>
                )}
              {isFilled && <>{Controls}</>}
            </div>
          </Col>
        </>
        <Col span={24}>
          <Space align={isSmallDevice ? "start" : "center"}>
            {isExpired ? (
              <ExclamationCircleFilled style={{ color: "#faad14" }} />
            ) : (
              <CheckCircleOutlined
                style={{ color: isFilled ? " #27CA2D" : "#dddddd" }}
              />
            )}

            <Title
              level={5}
              className="tradeGothic "
              style={{ marginBottom: 0 }}
            >
              {title}
            </Title>
            {isFilled && (
              <Text
                className="tradeGothic "
                style={{
                  fontSize: 14,
                  textAlign: "right",
                  display: "inline-block",
                }}
              >
                {date ? (
                  isExpired ? (
                    <>Expired</>
                  ) : (
                    <>
                      {moment.utc(date).local().format("MMM DD, YYYY h:mm A")} (
                      {moment.tz.guess()})
                    </>
                  )
                ) : (
                  ""
                )}
              </Text>
            )}
          </Space>
        </Col>
        {/* {type === FormType.goodFaithExam && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span> NP/Physican 1 </span>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                className="standaloneSearch"
                placeholder="Search to Select"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={filteredStaff}
              />
            </div>
            <div>
              <span> NP/Physican 2</span>
              <Select
                variant="outlined"
                showSearch
                style={{ width: 200 }}
                className="standaloneSearch"
                placeholder="Search to Select"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={filteredStaff}
              />
            </div>
          </div>
        )} */}
      </Row>
      <AuditList
        user={user}
        open={showAudit}
        onCancel={() => setShowAudit(false)}
        type={type}
        itemTitle={title}
      />
    </>
  );
}

IntakeFormCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string | PropTypes.any, // prob datetime
  isFilled: PropTypes.bool,
  type: PropTypes.string,
  modalVisibilityHander: PropTypes.bool,
  variant: PropTypes.string,
};

export default IntakeFormCard;
