import ReactDOM from "react-dom";

import { Form, Space } from "antd";
import { Button } from "components/Buttons/Buttons";
import { useSignature } from "components/Signature/Signature";
import FormParsedUI from "containers/FormsManagement/components/FormParseUi";
import usePortal from "hooks/usePortal";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  getChart,
  postChartValues,
  putChartValues,
} from "services/forms.service";
import { openNotificationWithIcon } from "utils/Notification";
import { setLoading } from "redux/actions/app.actions";

import { getChartNotes, addChartNotes } from "services/charts.services";
import { FormInputArea } from "components/FormItems/FlatFormItems";

const DATE_INDICATOR = "{{DATE}}";

export default function ChartV2() {
  const location = useLocation();
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [chart, setChart] = useState();
  const [chartNotes, setChartNotes] = useState([]);

  const currentStaff = useSelector((state) => state.user.currentUser);
  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");
  const [formDesign, setFormDesign] = useState();

  const loadData = (renderDesign = true) => {
    if (params?.orderId && params?.lineItemId) {
      dispatch(getChart(params?.orderId, params?.lineItemId)).then(
        async (res) => {
          if (res?.status === "success") {
            const data = res?.data;
            setChart(data);
            if (renderDesign) {
              const {
                designJSON,
                valueJSON,
                staffSignature,
                physicianSignature,
              } = data ?? {};
              const json = JSON.parse(designJSON || "{}");
              if (json?.length > 0) setFormDesign(json);

              const values = JSON.parse(valueJSON || "{}");
              let transformedValues = {};
              const keys = Object.keys(values);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = values[key];
                if (value?.startsWith?.(DATE_INDICATOR)) {
                  const dateString = value.split(DATE_INDICATOR).pop();
                  const date = moment(dateString);
                  transformedValues[key] = date;
                } else transformedValues[key] = value;
              }
              setSignatureValue(staffSignature);
              setSignatureValue1(physicianSignature);
              form.setFieldsValue(transformedValues);
              const notes = await getChartNotes(data?.chartId);
              setChartNotes(notes);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    loadData();
  }, [params]);

  // handlers
  const handlePut = (values, callback) => {
    dispatch(
      putChartValues(
        {
          orderId: chart?.orderId,
          orderLineItemId: chart?.orderLineItemId,
          ...values,
        },
        chart?.chartId
      )
    ).then((res) => {
      if (res?.status === "success") {
        if (callback) callback();
        else loadData(false);
      }
    });
  };

  /** handleSave just updates the formValuesJson */
  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value)) {
        const dateWithOutTimezone =
          value.format("YYYY-MM-DD") + "T00:00:00";
        console.log("dateWidthoutTimeone", dateWithOutTimezone);
        transformedValues[key] = DATE_INDICATOR + dateWithOutTimezone;
      } else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSave = () => {
    const values = form.getFieldsValue(true);
    const transformedValues = getSubmitableData(values);
    handlePut({
      chart: JSON.stringify(transformedValues),
    });
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue(true);

    let staffSignature = chart?.staffSignature;
    let physicianSignature = chart?.physicianSignature;
    const transformedValues = getSubmitableData(values);
    if (currentStaff?.staffId === chart?.staffId && !chart.staffSignature) {
      if (getSignatureValue()) {
        staffSignature = getSignatureValue();
      } else {
        return openNotificationWithIcon(
          "warning",
          "Signature Required",
          "Please sign the chart before submitting"
        );
      }
    } else if (currentStaff?.staffId === chart?.staffPhysicianId) {
      if (getSignatureValue1()) {
        physicianSignature = getSignatureValue1();
      } else {
        return openNotificationWithIcon(
          "warning",
          "Signature Required",
          "Please sign the chart before submitting"
        );
      }
    }
    handlePut(
      {
        chart: JSON.stringify(transformedValues),
        staffSignature,
        physicianSignature,
      },
      () => {
        setTimeout(() => {
          window.close();
        }, 3000);
      }
    );
  };

  const NOTES_CONTAINER_ID = "notesPortal";
  const [isNotesContainerLoaded, setIsNotesContainerLoaded] = useState(false);
  useEffect(() => {
    const targetNode = document.getElementById("content-container");
    const config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          for (let node of mutation.addedNodes) {
            if (node.id === "signatureContainer") {
              setIsNotesContainerLoaded(true);
              observer.disconnect();
            }
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }, []);

  let notes = <></>;
  if (isNotesContainerLoaded) {
    notes = ReactDOM.createPortal(
      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #EFF1F4",
            padding: "20px 0px",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
            }}
          >
            Notes
          </h1>
        </div>
        <div>
          <div>
            {chartNotes.map((note) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 18,
                      marginBottom: 0,
                    }}
                  >
                    {note.noteDescription}
                  </p>
                  <span>
                    Created on{" "}
                    {moment
                      .utc(note.createdOn)
                      .local()
                      .format("D MMM YYYY h:mm A")}
                  </span>
                </div>
                (Created By: {note.userName})
              </div>
            ))}
          </div>
          <FormInputArea
            name={"noteDescription"}
            label={"Note"}
            disabled={
              !(
                currentStaff?.staffId === chart?.staffId ||
                currentStaff?.staffId === chart?.staffPhysicianId ||
                currentStaff?.roleName === "Admin"
              )
            }
          />
          <Button
            rounded={true}
            disabled={
              !(
                currentStaff?.staffId === chart?.staffId ||
                currentStaff?.staffId === chart?.staffPhysicianId ||
                currentStaff?.roleName === "Admin"
              )
            }
            onClick={async () => {
              const noteDescription = form.getFieldValue("noteDescription");
              if (
                noteDescription === undefined ||
                noteDescription?.trim() === ""
              ) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please enter a note description"
                );
                return;
              }
              dispatch(setLoading(true));
              await addChartNotes(chart?.chartId, { noteDescription });
              const notes = await getChartNotes(chart?.chartId);
              setChartNotes(notes);
              form.setFieldsValue({ noteDescription: "" });
              dispatch(setLoading(false));
            }}
          >
            Add Note
          </Button>
        </div>
      </div>,
      document.getElementById(NOTES_CONTAINER_ID)
    );
  }

  if (!chart) return <></>;

  return (
    <div>
      <Form
        id="chart-form"
        form={form}
        layout="vertical"
        requiredMark={true}
        style={{
          overflow: "hidden",
          overflowY: "scroll",
          height: "calc(100vh - 86px )",
          minHeight: 400,
          padding: "0px 24px",
          paddingBottom: 300,
          paddingRight: 20,
        }}
        onFinish={handleSubmit}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        {notes}

        <FormParsedUI
          disabled={chart?.staffSignature}
          addNotesPortal={true}
          data={formDesign}
          form={form}
          signature={signature}
          getSignatureValue={getSignatureValue}
          clearSignatureValue={clearSignatureValue}
          setSignatureValue={setSignatureValue}
          signature1={signature1}
          getSignatureValue1={getSignatureValue1}
          clearSignatureValue1={clearSignatureValue1}
          setSignatureValue1={setSignatureValue1}
          isSignatureDisabled={
            chart?.staffSignature || currentStaff?.staffId !== chart?.staffId
          }
          isSignature1Disabled={
            chart?.staffPhysicianId !== currentStaff?.staffId ||
            chart?.physicianSignature
          }
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 367,
            paddingBottom: 20,
          }}
        >
          <Button
            rounded={true}
            style={{ marginRight: 20 }}
            disabled={
              chart?.staffSignature || currentStaff?.staffId !== chart?.staffId
            }
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            rounded={true}
            htmlType="submit"
            disabled={
              !(
                (chart?.staffId === currentStaff?.staffId &&
                  !chart?.staffSignature) ||
                (currentStaff?.staffId === chart?.staffPhysicianId &&
                  !chart?.physicianSignature)
              )
            }
          >
            Save & Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
