import React, { useMemo } from "react";
import moment from "moment";

import styles from "../Charts.module.scss";
import { Select } from "antd";

const { Option } = Select;

export default function ChartCard({
  chart,
  onClick,
  onNPChange,
  currentStaffRoleName,
  filteredStaff,
}) {
  const hours = useMemo(() => {
    return parseInt(
      moment
        .utc()
        .local()
        .diff(moment.utc(chart.staffSignedOffDate).local(), "hours")
    );
  }, [chart.staffSignedOffDate]);

  const staff = useMemo(() => {
    return filteredStaff.filter(
      (staff) =>
        staff.isActive === true || staff.staffId === chart.staffPhysicianId
    );
  }, [filteredStaff, chart]);

  return (
    <div
      key={chart.chartId}
      className={styles.card}
      style={{
        cursor: "pointer",
      }}
    >
      <div className={styles.section1} onClick={onClick}>
        <p className={styles.largeFont}>{chart.guestName}</p>
        <p className={styles.normalFont}>{chart.chartName}</p>
      </div>
      <div className={styles.section2} onClick={onClick}>
        <div>
          <p className={styles.normalFont}>Clinician</p>
          <p className={styles.largeFont}>{chart.staffName}</p>
        </div>
        <div>
          <p className={styles.boldFont}>Status</p>
          <div className={styles.signaturesTextContainer}>
            <p
              className={styles.boldFont}
              style={{
                color: "#27CA2D",
              }}
            >
              {chart.staffName
                ? chart.staffSignature
                  ? "Signed off"
                  : "Pending"
                : "Unassigned"}
            </p>
            <p className={styles.signatureText}>
              {chart.staffSignedOffDate &&
                moment
                  .utc(chart.staffSignedOffDate)
                  .local()
                  .format("ddd Do MMM YYYY hh:mm a")}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.section3}>
        <div>
          <p className={styles.normalFont}>NP/Physician</p>
          <Select
            className={["home_select", styles.selector]}
            // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
            placeholder={"Select"}
            value={chart.staffPhysicianId ? chart.staffPhysicianId : null}
            disabled={
              !(
                currentStaffRoleName === "Nurse Practitioner" ||
                currentStaffRoleName === "Nurse Lead" ||
                currentStaffRoleName === "Admin"
              ) ||
              // chart.staffPhysicianId ||
              !chart.staffSignature
            }
            onChange={onNPChange}
          >
            {staff.map((staff) => (
              <Option key={staff.staffId} value={staff.staffId}>
                {staff.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.flexJustifyBetweenAlignEnd}>
          <div>
            <p className={styles.boldFont}>Status</p>
            <div className={styles.signaturesTextContainer}>
              <p
                className={styles.boldFont}
                style={{
                  color: "#8E94AA",
                }}
              >
                {chart.staffPhysicianId
                  ? chart.physicianSignature
                    ? "Signed off"
                    : "Pending"
                  : "Unassigned"}
              </p>
              <p className={styles.signatureText}>
                {chart.physicianSignedOffDate &&
                  moment
                    .utc(chart.physicianSignedOffDate)
                    .local()
                    .format("ddd Do MMM YYYY hh:mm a")}
              </p>
            </div>
          </div>
          <div>
            {chart.staffSignature &&
              chart.staffSignedOffDate &&
              !chart.physicianSignature && (
                <div
                  className={styles.badge}
                  style={{
                    background:
                      hours < 24 ? "#27CA2D" : hours ? "#E4C865" : "#FF0000",
                  }}
                >
                  {hours} hours
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
