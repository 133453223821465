import { CloseOutlined } from "@ant-design/icons";
import Drawer from "components/Drawer/Drawer";
import React, { useEffect, useState } from "react";

import { Button as AntdButton, List } from "antd";
import { Button } from "components/Buttons/Buttons";
import GiftCardItem from "./GiftCardItem";
import GiftCardModal from "./GiftCardModal";


import styles from "./GiftCardRow.module.scss"

export default function GiftCardsList({
  visible,
  giftCards,
  onClose,
  customer,
  paymentMethod,
  refreshGiftCards,
  refreshCredits,
}) {
  const [showAdd, setShowAdd] = useState(false);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="serviceDrawer"
      width={"min(100vw, 420px)"}
      text="Gift Cards"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="drawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
         
        </div>
      }
    >
      <div className={styles.giftCardsList}>
        <List
          locale={{
            emptyText: "No Gift Cards Found.",
          }}
          dataSource={giftCards}
          renderItem={(giftCard) => (
            <GiftCardItem
              key={giftCard.giftCardId}
              giftCard={giftCard}
              paymentMethod={paymentMethod}
              customer={customer}
              refreshGiftCards={refreshGiftCards}
              refreshCredits={refreshCredits}
            />
          )}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [15, 25, 50, 100, 250],
          }}
        />
      </div>
    </Drawer>
  );
}
