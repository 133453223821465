import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  FormInput,
  FormInputArea,
  FormButton,
} from "components/FormItems/FlatFormItems";
import {
  Radio,
  Input,
  Form,
  Typography,
  Row,
  Col,
  Skeleton,
  Alert,
  Space,
} from "antd";
import { FormDatePicker } from "components/FormItems/FlatFormItems";
import {
  getGiftCardById,
  getGiftCardDesign,
  getPromoGiftCardDesign,
  purchaseGiftCard,
  updateGiftCard,
} from "services/giftCards.service";
import { get } from "lodash";
import Spinner from "components/Spinner/Spinner";
import { getPurchasablePromoGCs } from "services/guests.services";
import { getProgramById } from "services/programsAndMembership.services";
import { BENEFIT_TYPE } from "utils/enums";

import styles from "./GiftCardRow.module.scss";

// Hardcoded ID provided by the backend to be used as productID for giftcard purchase
const GIFT_CARD_ID = 1148;

const { Title } = Typography;

function GiftCardModal({ visible, onCancel, onSave, paymentMethod, customer }) {
  const isEdit = visible?.giftCardId;

  const [form] = Form.useForm();
  const value = Form.useWatch("amount", form);
  const promotionalProductId = Form.useWatch("promotionalProductId", form);

  const [design, setdesign] = useState({
    data: null,
    isLoading: true,
  });

  const [promoGCs, setPromoGCs] = useState({
    data: null,
    isLoading: true,
  });

  const [promoTCs, setPromoTCs] = useState({
    data: null,
    isLoading: true,
  });

  const [showTCs, setShowTCs] = useState(false);
  const [formInfo, setFormInfo] = useState({
    data: null,
    isLoading: false,
  });

  const [programDetail, setProgramDetail] = useState({
    data: null,
    isLoading: true,
  });


  
  const shouldShowPromoTCs =
    promotionalProductId || formInfo?.data?.programName ? true : false;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGiftCardDesign())
      .then((res) => {
        if (res?.status === "success") {
          setdesign({
            data: res?.data,
            isLoading: false,
          });
        }
      })
      .finally(() => {
        setdesign((prev) => ({ ...prev, isLoading: false }));
      });
    dispatch(getPromoGiftCardDesign())
      .then((res) => {
        if (res?.status === "success") {
          console.log('res?.data', res?.data)
          setPromoTCs({
            data: res?.data,
            isLoading: false,
          });
        }
      })
      .finally(() => {
        setPromoTCs((prev) => ({ ...prev, isLoading: false }));
      });
  }, []);

  useEffect(() => {
    if (visible) {
      // if purchasing
      if (!isEdit) {
        dispatch(getPurchasablePromoGCs(customer.customerId))
          .then((res) => {
            if (res.status === "success") {
              setPromoGCs({
                data: res.data,
                isLoading: false,
              });
            } else {
              setPromoGCs({
                data: null,
                isLoading: false,
              });
            }
          })
          .catch(() => {
            setPromoGCs({
              data: null,
              isLoading: false,
            });
          });
      }
    } else {
      setPromoGCs({
        data: null,
        isLoading: true,
      });
      setProgramDetail({
        data: null,
        isLoading: true,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setFormInfo({
        data: null,
        isLoading: false,
      });
      setPromoGCs({
        data: null,
        isLoading: true,
      });
      setProgramDetail({
        data: null,
        isLoading: true,
      });
    } else if (visible.giftCardId) {
      setFormInfo((state) => ({ ...state, isLoading: true }));

      dispatch(getGiftCardById(visible.giftCardId))
        .then((res) => {
          if (res?.status === "success") {
            // if (res.data.promotionalProductId) {
            //   dispatch(getProgramById(res.data.promotionalProductId)).then(
            //     (res) => {
            //       if (res?.status === "success") {
            //         setProgramDetail({
            //           data: res?.data,
            //           isLoading: false,
            //         });
            //       }
            //     }
            //   );
            // } else {
            //   setProgramDetail({
            //     data: null,
            //     isLoading: false,
            //   });
            // }

            const data = res?.data ?? {};
            setFormInfo((state) => ({ ...state, data }));
            form.setFieldsValue({
              ...data,
              scheduledSendDate: data.scheduledSendDate
                ? moment(res.scheduledSendDate)
                : null,
            });
          }
        })
        .finally(() => {
          setFormInfo((state) => ({ ...state, isLoading: false }));
        });
    }
  }, [visible]);

  const renderForm = () => {
    const {
      sellingPrices = [50, 100, 150],
      customValueEnabled = true,
      notes,
      minimumValue = 200,
    } = design.data ?? {};
    return (
      <>
        {formInfo?.data?.hasInvalidEmail && (
          <Alert
            message="Invalid Receiver Email Provided."
            type="error"
            className={styles.giftcardFeedbackAlert}
            closable
          />
        )}
        <Title
          level={4}
          className="tradeGothic"
          style={{
            color: "#000",
          }}
        >
          Gift Card Amount
        </Title>
        {isEdit ? (
          <>
            {formInfo?.isLoading ? (
              <Spinner />
            ) : (
              <>
                <Typography.Text>${formInfo?.data?.amount}</Typography.Text>
                {formInfo?.data?.programName && (
                  <Typography.Text>
                    {" "}
                    ({formInfo?.data?.programName})
                  </Typography.Text>
                )}

                {/* {programDetail?.isLoading ? (
                  <Spinner />
                ) : (
                  programDetail?.data && (
                    <>
                      <div>
                        <Typography.Text>Program Name: </Typography.Text>
                        <Typography.Text>
                          {programDetail?.data?.name}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text>
                          Discount:{" "}
                          {programDetail?.data?.benefitType ===
                            BENEFIT_TYPE["Discount Amount"] && "Amount"}
                          {programDetail?.data.benefitType ===
                            BENEFIT_TYPE["Discount Percentage"] &&
                            "Percentage"}{" "}
                        </Typography.Text>
                        <Typography.Text>
                          {programDetail?.data?.discountValue}{" "}
                          {programDetail?.data.benefitType ===
                            BENEFIT_TYPE["Discount Percentage"] && "%"}
                        </Typography.Text>
                      </div>
                    </>
                  )
                )} */}
              </>
            )}
          </>
        ) : (
          <>
            <Form.Item
              name="amount"
              style={{ marginBottom: 20 }}
              rules={[
                {
                  required: !promotionalProductId ? true : false,
                  message: "amount required",
                },
              ]}
              disabled={isEdit}
            >
              <Radio.Group
                disabled={isEdit}
                className="giftCardValues"
                onChange={() => {
                  form.setFieldValue("promotionalProductId", undefined);
                }}
                // onChange={(e, value) => setValue(e.target.value)}
              >
                {sellingPrices?.map((price) => {
                  return <Radio value={price}>${price}</Radio>;
                })}
                {customValueEnabled && (
                  <Radio value={"custom"}>
                    {value === "custom" ? (
                      <Form.Item
                        className="no-error-field"
                        style={{ margin: 0 }}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            min: 1,
                          },
                        ]}
                        name="customValue"
                        required
                      >
                        <Input
                          disabled={isEdit}
                          min={minimumValue}
                          prefix="$"
                          type="number"

                          // onChange={(e) => setCustomValue(Number(e.target.value))}
                          // value={customValue}
                        />
                      </Form.Item>
                    ) : (
                      <> Custom</>
                    )}
                  </Radio>
                )}
              </Radio.Group>
            </Form.Item>

            {promoGCs?.isLoading ? (
              <Spinner />
            ) : (
              <Row hidden={promoGCs.data?.length === 0}>
                <Col>
                  <Title
                    level={4}
                    className="tradeGothic"
                    style={{
                      color: "#000",
                    }}
                  >
                    Promo Gift Card
                  </Title>
                  <Form.Item
                    name="promotionalProductId"
                    style={{ marginBottom: 20 }}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "amount required",
                    //   },
                    // ]}
                    disabled={isEdit}
                  >
                    <Radio.Group
                      disabled={isEdit}
                      className="giftCardValues"
                      onChange={() => {
                        form.setFieldValue("amount", undefined);
                      }}
                      // onChange={(e, value) => setValue(e.target.value)}
                    >
                      <Space direction="vertical">
                        {promoGCs.data?.map((promoGC) => {
                          return (
                            <Radio value={promoGC.productId}>
                              {promoGC.programName} (${promoGC.sellingPrice?.toFixed?.(2)})
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}

        <FormInputArea
          disabled={isEdit}
          label="Personal Notes"
          name="personalNotes"
          variant="underlined"
        />

        <FormDatePicker
          form={form}
          disabled={isEdit}
          placeholder={"Send Date"}
          label={"Send Date"}
          name="scheduledSendDate"
          variant="underlined"
          formItemStyles={{ marginBottom: 14 }}
          required
          validateFirst
          rules={[
            {
              required: true,
              message: "*Required",
            },
          ]}
          disabledDate={(current) =>
            current.isSameOrBefore(
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            )
          }
        />
        <FormButton
          type="submit"
          htmlType="submit"
          text={isEdit ? "Update" : "Make Payment"}
          rounded={true}
          disabled={isEdit || !paymentMethod}
          formItemStyles={{ marginBottom: 12 }}
        />
        <Typography.Link
          disabled={!design?.data?.notes}
          underline
          onClick={() => setShowTCs(true)}
        >
          {shouldShowPromoTCs && "Promo"} Gift Card Terms & Conditions
        </Typography.Link>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <div>
        <Skeleton
          active
          paragraph={{
            rows: 6,
          }}
        />
      </div>
    );
  };

  function renderNotes() {
    console.log('renderNotes', shouldShowPromoTCs)
    if (shouldShowPromoTCs) {
      if (promoTCs.isLoading) {
        return <Spinner />;
      } else return promoTCs.data?.notes;
    } else if (design.isLoading) {
      return <Spinner />;
    } else {
      return design.data?.notes;
    }
  }

  return (
    <Modal
      className="giftCardModal"
      width="500px"
      title={isEdit ? "View a Gift Card" : "Send a Gift Card"}
      footer={null}
      onCancel={() => onCancel()}
      visible={visible}
      //   onOk={() => console.log("ok")}
      //   okText="Add"
      centered={true}
      //   footer={[<Button rounded={true}>Save</Button>]}
      //   cancelText="No"
    >
      <Form
        form={form}
        initialValues={
          {
            // firstName: user.firstName,
            // lastName: user.lastName,
          }
        }
        requiredMark={false}
        layout="vertical"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log("err is human", errorFields);
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(formValues) => {
          const { scheduledSendDate, amount, customValue, ...values } =
            formValues;
          let data = {
            ...values,
            giftCardPrice: 0,
          };
          data.scheduledSendDate = moment(scheduledSendDate).format();
          if (amount === "custom") {
            data.giftCardPrice = formValues.customValue;
          } else {
            data.giftCardPrice = formValues.amount;
          }
          data.paymentMethodId = paymentMethod?.paymentMethodId;
          data.senderCustomerId = customer.customerId;
          data.recipientCustomerId = customer.customerId;
          data.recipientFirstName = customer.firstName;
          data.recipientLastName = customer.lastName;
          data.recipientUserName = customer.userName;
          data.email = customer.email;

          if (isEdit) {
            const { firstName, lastName } = data;
            dispatch(
              updateGiftCard(visible.giftCardId, { firstName, lastName })
            ).then((res) => {
              if (res?.status === "success") {
                onSave();
              }
            });
          } else {
            dispatch(purchaseGiftCard(data)).then((res) => {
              if (res?.status === "success") {
                onSave();
              }
            });
          }
        }}
      >
        {design?.isLoading ? renderLoader() : renderForm()}
      </Form>
      <Modal
        title="Gift Card Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <Typography.Text level={4} className="tradeGothic">{renderNotes()}</Typography.Text>
      </Modal>
    </Modal>
  );
}

GiftCardModal.propTypes = {};

export default GiftCardModal;
