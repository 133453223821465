import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form, Row, Col, Typography, Button as AntdButton, Tag } from "antd";
import "../Programs.style.scss";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
  FormRadioGroup,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { FormSelect } from "components/FormItems/FlatFormItems";
import moment from "moment";
import {
  BENEFIT_TYPE,
  BILLING_FREQUENCY,
  EXCLUSION_TYPE,
  PRODUCT_TYPE,
  PROGRAM_TYPE,
} from "utils/enums";
import ReviewProgram from "components/ReviewProgram/ReviewProgram.index";
import { intersectionWith } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  createProgram,
  getProgramById,
  updateProgram,
  createReferral,
  getReferralById,
  updateReferral,
  updateVoucher,
  createVoucher,
  getVoucherById,
} from "services/programsAndMembership.services";
import { sub } from "date-fns";
import { PercentageArray } from "utils/common";

const { Title, Text } = Typography;

// const PROGRAM_TYPE = [
//   {
//     label: "Program",
//     value: 1,
//   },
//   { label: "Membership", value: 2 },
//   { label: "Voucher Code", value: 3 },
//   { label: "Referral", value: 4 },
//   { label: "Gift Card", value: 5 },
// ];

function ProgramCreateModal({
  products,
  data = {},
  visible,
  onCancel,
  onSuccess,
  taxCategories = [],
}) {
  // props destruct start
  // props destruct end

  // hooks that bring data start
  const step1SubmitRef = useRef();
  const step2SubmitRef = useRef();
  const dispatch = useDispatch();
  // hooks that bring data end

  // states start
  const [step, setStep] = useState(1);

  // states end

  const { allFacilities, isFetchingFacilities } = useSelector(
    (state) => state.facilities
  );
  // hooks that dont bring data start
  const [formStep1] = useForm();
  const [formStep2] = useForm();

  const programType = Form.useWatch("programType", formStep1);
  const benefitType = Form.useWatch("benefitType", formStep1);
  const productIds = Form.useWatch("productIds", formStep1);
  const productExcludeType = Form.useWatch("productExcludeType", formStep1);
  const facilityExcludeType = Form.useWatch("facilityExcludeType", formStep1);

  // variables start
  const isGiftcardOrReferral =
    programType === PROGRAM_TYPE.Referral ||
    programType === PROGRAM_TYPE["Gift Card"];
  const isVoucher = programType === PROGRAM_TYPE["Voucher Code"];
  const isPromoGiftCard = programType === PROGRAM_TYPE["Promotional Gift Card"];

  const isTaxCategoriesRequired =
    PROGRAM_TYPE.Program === programType ||
    PROGRAM_TYPE.Membership === programType;

  const isFirstStep = step === 1;
  const isLastStep = step === 2 || isGiftcardOrReferral || isVoucher;
  // variables end

  const isEdit = data?.programId || data?.referralPolicyId || data?.voucherId;

  const loadData = () => {
    dispatch(getProgramById(data?.programId)).then((res) => {
      if (res?.status === "success") {
        formStep1.setFieldsValue({
          ...res?.data,
          fromDate: res?.data?.fromDate
            ? moment(res?.data?.fromDate)
            : undefined,
          tillDate: res?.data?.tillDate
            ? moment(res?.data?.tillDate)
            : undefined,
          facilityIds: res?.data?.facilities?.map((it) => it.facilityId),
        });
        formStep2.setFieldsValue({
          ...res?.data,
          programApplications:
            res?.data?.programApplications?.length === 0 ||
            !res?.data?.programApplications
              ? [1, 2]
              : res?.data?.programApplications,
        });
      }
    });
  };

  const loadReferralData = () => {
    dispatch(getReferralById(data?.referralPolicyId)).then((res) => {
      if (res?.status === "success") {
        formStep1.setFieldsValue({
          ...res.data,
          programType: PROGRAM_TYPE.Referral,
          programName: res.data.referralPolicyName,
          notes: res.data.termsAndConditions,
        });
      }
    });
  };

  const loadVoucherData = () => {
    dispatch(getVoucherById(data?.voucherId)).then((res) => {
      if (res?.status === "success") {
        console.log(
          "res",
          res,
          res?.data?.facilities?.map((it) => it.facilityId)
        );
        formStep1.setFieldsValue({
          ...res.data,
          programType: PROGRAM_TYPE["Voucher Code"],
          programName: res.data?.voucherCode,
          notes: res.data.termsAndConditions,
          fromDate: res.data?.validFrom
            ? moment(res.data?.validFrom)
            : undefined,
          tillDate: res.data?.validTill
            ? moment(res.data?.validTill)
            : undefined,
          benefitType: res.data?.discountType,
          discountValue: res.data?.discount,
          facilityIds: res?.data?.facilities?.map((it) => it.facilityId),
          applications:
            res?.data?.applications?.length === 0 || !res?.data?.applications
              ? [1, 2]
              : res?.data?.applications,
          facilityExcludeType:
            res?.data?.facilityExcludeType === 0
              ? 1
              : res?.data?.facilityExcludeType,
        });
      }
    });
  };

  useEffect(() => {
    if (visible && data?.programId) {
      console.log("setting", data);
      formStep1.setFieldsValue({
        ...data,
        fromDate: data?.fromDate ? moment(data?.fromDate) : undefined,
        tillDate: data?.tillDate ? moment(data?.tillDate) : undefined,
      });
      formStep2.setFieldsValue({
        ...data,
      });
      loadData();
    } else {
      if (data?.referralPolicyId) {
        loadReferralData();
      } else if (data?.voucherId) {
        loadVoucherData();
      } else {
        setStep(1);
        formStep1.resetFields();
        formStep2.resetFields();
      }
    }
  }, [visible]);

  // useEffect(() => {

  // }, [programType]);

  const benefitTypes = Object.entries(BENEFIT_TYPE).map((it) => {
    return {
      label: it[0],
      value: it[1],
    };
  });

  const exclusionTypes = Object.entries(EXCLUSION_TYPE).map((it) => {
    return {
      label: it[0],
      value: it[1],
    };
  });

  const billingFrequencies = Object.entries(BILLING_FREQUENCY).map((it) => {
    return {
      label: it[0],
      value: it[1],
    };
  });

  const form1Data = useMemo(() => {
    if (formStep1 && isLastStep) return formStep1.getFieldsValue();
    return {};
  }, [isLastStep]);
  // hooks that dont bring data end

  // functions start
  const handleNextStep = () => {
    if (isFirstStep) {
      step1SubmitRef?.current?.click();
    } else {
      step2SubmitRef?.current?.click();
    }
  };

  // handleSubmit
  const handleSubmit = useCallback(
    (values) => {
      // generate facilities
      let submitableFacilities = [];
      let form1Values = formStep1.getFieldsValue();
      let form2Values = formStep2.getFieldsValue();

      if (values.facilityExcludeType !== EXCLUSION_TYPE["All Included"]) {
        submitableFacilities = form1Values.facilityIds?.map?.((it) => {
          let fullDetail = allFacilities?.find?.((it2) => {
            return it2.facilityId === it;
          });
          return {
            facilityId: fullDetail.facilityId,
            operationTypeId: fullDetail.operationTypeIds?.[0],
            facilityOperationTypeId: fullDetail?.facilityOperationTypeIds?.[0],
          };
        });
      }

      if (values.programType === PROGRAM_TYPE.Referral) {
        if (data?.referralPolicyId) {
          dispatch(
            updateReferral(data.referralPolicyId, {
              referralPolicyName: values.programName,
              termsAndConditions: values.notes,
              guestDiscount: values.guestDiscount,
              friendDiscount: values.friendDiscount,
              incentiveValidForDays: values.incentiveValidForDays,
            })
          ).then((res) => {
            if (res?.status === "success") onSuccess?.();
          });
        } else {
          dispatch(
            createReferral({
              referralPolicyName: values.programName,
              termsAndConditions: values.notes,
              guestDiscount: values.guestDiscount,
              friendDiscount: values.friendDiscount,
              incentiveValidForDays: values.incentiveValidForDays,
            })
          ).then((res) => {
            if (res?.status === "success") onSuccess?.();
          });
        }
      } else if (values.programType === PROGRAM_TYPE["Voucher Code"]) {
        if (data?.voucherId) {
          dispatch(
            updateVoucher(data.voucherId, {
              // voucherName: values.programName,
              voucherCode: values.programName,
              discountType: values.benefitType,
              discount: values.discountValue,
              validFrom: values.fromDate,
              validTill: values.tillDate,
              facilities: submitableFacilities,
              facilityExcludeType: values.facilityExcludeType,
              applications: values.applications,
              redemptionLimit: values.redemptionLimit,
              facilityIds: values.facilityIds,
            })
          ).then((res) => {
            if (res?.status === "success") onSuccess?.();
          });
        } else {
          dispatch(
            createVoucher({
              // voucherName: values.programName,
              voucherCode: values.programName,
              discountType: values.benefitType,
              discount: values.discountValue,
              validFrom: values.fromDate,
              validTill: values.tillDate,
              facilities: submitableFacilities,
              facilityExcludeType: values.facilityExcludeType,
              applications: values.applications,
              redemptionLimit: values.redemptionLimit,
              facilityIds: values.facilityIds,
            })
          ).then((res) => {
            if (res?.status === "success") onSuccess?.();
          });
        }
      } else if (isLastStep) {
        const submittableValues = { ...form1Values, ...form2Values };
        if (programType === PROGRAM_TYPE.Program) {
          submittableValues.benefitType = BENEFIT_TYPE["Bonus Service"];
        }
        submittableValues.productIds = submittableValues.productIds ?? [];
        submittableValues.fromDate = submittableValues.fromDate?.format();
        submittableValues.tillDate = submittableValues.tillDate?.format();

        submittableValues.facilities = submitableFacilities;

        if (programType === PROGRAM_TYPE["Promotional Gift Card"]) {
          submittableValues.productTaxCategoryId = 1;
        }

        if (data?.programId) {
          dispatch(updateProgram(data.programId, submittableValues)).then(
            (res) => {
              if (res?.status === "success") onSuccess?.();
            }
          );
        } else {
          dispatch(createProgram(submittableValues)).then((res) => {
            if (res?.status === "success") onSuccess?.();
          });
        }
      } else {
        setStep((step) => step + 1);
      }
    },
    [programType, isLastStep, data]
  );
  const handlePreviousStep = () => {
    if (isFirstStep) {
      onCancel?.();
      // close it
    } else {
      setStep((step) => step - 1);
    }
  };
  // functions end

  return (
    <Modal
      className="ant-modal-title-tradeGothic"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Add/Edit Program"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton
            type="text"
            onClick={handlePreviousStep}
            style={{ padding: 0 }}
          >
            {isFirstStep ? "Cancel" : "Back"}
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={handleNextStep}

            //   disabled={!Boolean(setupIntent)}
          >
            {isLastStep ? "Submit" : "Next"}
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={formStep1}
        initialValues={{
          facilityExcludeType: 1,
          productExcludeType: 1,
        }}
        layout="vertical"
        requiredMark={false}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log(errorFields);
          formStep1.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
        style={{
          height: !isFirstStep ? 0 : "auto",
          overflowY: !isFirstStep ? "hidden" : "auto",
          overflowX: "hidden",
        }}
        preserve={true}
      >
        <FormSelect
          hidden={true}
          variant="underlined"
          name="facilityExcludeType"
          label="Facility Exclusion Type"
          placeholder="Select exclusion type"
          formItemStyles={{ marginBottom: 0 }}
          options={[]}
          // isOptionDisabled={(it) => !it.isActive}

          renderLabel="label"
          renderValue="value"
        />
        <Row gutter={[32, 16]}>
          <Col xs={24} md={12}>
            <FormSelect
              required
              variant="underlined"
              name={"programType"}
              renderLabel="label"
              renderValue={"value"}
              formItemStyles={{
                marginBottom: 0,
              }}
              // options={facilities ?? []}
              options={Object.entries(PROGRAM_TYPE).map((it) => {
                return {
                  label: it[0],
                  value: it[1],
                };
              })}
              label={"Program Type"}
              placeholder={"Select Program Type"}
              emptyMessage="No Program Type Available"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
              disabled={isEdit}
              onChange={() => {
                formStep1.setFieldsValue({
                  purchaseAmount: undefined,
                  free: undefined,
                  discountType: undefined,
                  amount: undefined,
                  facilityIds: undefined,
                  productIds: undefined,
                  excludedProductIds: undefined,
                });
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="programName"
              label={
                programType === PROGRAM_TYPE.Referral
                  ? "Referral Name"
                  : "Program Name"
              }
              placeholder={
                programType === PROGRAM_TYPE.Referral
                  ? "Referral Name"
                  : "Program Name"
              }
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          {(programType === PROGRAM_TYPE.Program ||
            isPromoGiftCard ||
            programType === PROGRAM_TYPE.Membership) && (
            <Col xs={24} md={12}>
              <FormInputNumber
                min={0}
                disabled={isEdit}
                variant="underlined"
                name="sellingPrice"
                label="Price"
                placeholder="Price"
                required
                formItemStyles={{
                  marginBottom: 0,
                }}
              />
            </Col>
          )}
          {programType === PROGRAM_TYPE.Program && (
            <>
              <Col xs={24} md={12}>
                <FormInputNumber
                  variant="underlined"
                  name="purchaseCount"
                  label="Purchase Number"
                  placeholder="Purchase Number"
                  required={true}
                  min={1}
                  formItemStyles={{
                    margin: 0,
                  }}
                  disabled={isEdit}
                />
              </Col>
              <Col xs={24} md={12}>
                <FormInputNumber
                  variant="underlined"
                  name="freeCount"
                  label="Free"
                  placeholder="Free"
                  required={true}
                  min={1}
                  formItemStyles={{
                    margin: 0,
                  }}
                  disabled={isEdit}
                />
              </Col>
            </>
          )}
          {(programType === PROGRAM_TYPE.Membership ||
            isVoucher ||
            isPromoGiftCard) && (
            <>
              <Col xs={24} md={12}>
                <FormSelect
                  required
                  variant="underlined"
                  disabled={isEdit}
                  name={"benefitType"}
                  renderLabel="label"
                  renderValue={"value"}
                  formItemStyles={{
                    marginBottom: 0,
                  }}
                  options={benefitTypes?.filter((it) => {
                    return (
                      it.label !== "Bonus Service" && it.label !== "REFERAL"
                    );
                  })}
                  label={"Discount Type"}
                  placeholder={"Select Discount Type"}
                  emptyMessage="No Discount Type Available"
                  onChange={(value) => {
                    formStep1.setFieldsValue({
                      // sellingPrice: undefined,
                      amount: undefined,
                    });
                    // dispatch(setTimer(moment().add(15, "minutes")));
                  }}
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                />
              </Col>
              <Col xs={24} md={12}>
                {benefitType === BENEFIT_TYPE["Discount Percentage"] ? (
                  <FormSelect
                    required
                    disabled={isEdit}
                    variant="underlined"
                    name={"discountValue"}
                    renderLabel="label"
                    renderValue={"value"}
                    formItemStyles={{
                      marginBottom: 0,
                    }}
                    showSearch={true}
                    filterOption={(input, option) => {
                      return option?.label
                        ?.toLowerCase?.()
                        .includes?.(input.toLowerCase());
                    }}
                    // options={facilities ?? []}
                    options={PercentageArray}
                    label={"Amount"}
                    placeholder={"Select Amount"}
                    onChange={(value) => {
                      // dispatch(setTimer(moment().add(15, "minutes")));
                    }}
                    rules={[
                      {
                        required: true,
                        message: false,
                      },
                    ]}
                  />
                ) : (
                  <>
                    <FormInputNumber
                      required
                      variant="underlined"
                      disabled={isEdit}
                      name="discountValue"
                      label="Amount"
                      placeholder="Amount"
                      min={
                        programType === PROGRAM_TYPE["Voucher Code"] ? false : 0
                      }
                      formItemStyles={{
                        margin: 0,
                      }}
                    />
                  </>
                )}
              </Col>
            </>
          )}
          {programType === PROGRAM_TYPE.Membership && (
            <>
              <Col xs={24} md={12}>
                {" "}
                <FormSelect
                  required
                  variant="underlined"
                  name={"billingFrequency"}
                  renderLabel="label"
                  renderValue={"value"}
                  formItemStyles={{
                    marginBottom: 0,
                  }}
                  initialValue={BILLING_FREQUENCY.Monthly}
                  options={billingFrequencies}
                  label={"Billing Frequency"}
                  placeholder={"Select Billing Frequency"}
                  onChange={(value) => {
                    formStep1.setFieldsValue({
                      // sellingPrice: undefined,
                      amount: undefined,
                    });
                    // dispatch(setTimer(moment().add(15, "minutes")));
                  }}
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                />
              </Col>
              <Col xs={24} md={12}>
                <FormInputNumber
                  // required
                  disabled={isEdit}
                  variant="underlined"
                  name="redemptionLimit"
                  label="Redemption Limit"
                  placeholder="Redemption Limit"
                  min={0}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
            </>
          )}
          {programType === PROGRAM_TYPE.Referral && (
            <>
              <Col xs={24} md={12}>
                <FormInputNumber
                  variant="underlined"
                  name="guestDiscount"
                  label="Guest Amount"
                  placeholder="Guest Amount"
                  required={true}
                  min={0}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <FormInputNumber
                  variant="underlined"
                  name="friendDiscount"
                  label="Friend Amount"
                  placeholder="Friend Amount"
                  required={true}
                  min={0}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
              <Col xs={24} md={12}>
                <FormInputNumber
                  variant="underlined"
                  name="incentiveValidForDays"
                  label="Valid For Days"
                  placeholder="Valid For Days"
                  required={true}
                  min={0}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
            </>
          )}
          {isGiftcardOrReferral && (
            <Col xs={24}>
              <Title
                variant={5}
                style={{
                  margin: 0,
                  fontSize: 16,
                  color: "#161F3A",
                  marginBottom: 12,
                }}
              >
                Terms & Conditions
              </Title>
              <FormInputArea
                name={"notes"}
                variant="underlined"
                formItemStyles={{ width: "100%", margin: 0 }}
              />
            </Col>
          )}
          {!isGiftcardOrReferral && !isVoucher && (
            <>
              {/* {programType === PROGRAM_TYPE.Program && ( */}
              <Col xs={24} md={12}>
                <FormSelect
                  variant="underlined"
                  name="productExcludeType"
                  label="Product Exclusion Type"
                  placeholder="Select exclusion type"
                  formItemStyles={{ marginBottom: 0 }}
                  options={exclusionTypes}
                  // isOptionDisabled={(it) => !it.isActive}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  renderLabel="label"
                  renderValue="value"
                  required
                />
              </Col>
              {/* )} */}
              <Col xs={24} md={12}>
                <FormSelect
                  variant="underlined"
                  name="productIds"
                  label="Services"
                  placeholder="Select services"
                  mode="multiple"
                  formItemStyles={{ marginBottom: 0 }}
                  options={products?.filter((it) => {
                    return (
                      it?.isActive || productIds?.includes?.(it?.productId)
                    );
                  })}
                  // isOptionDisabled={(it) => !it.isActive}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  renderLabel="productName"
                  renderValue="productId"
                  disabled={
                    productExcludeType === EXCLUSION_TYPE["All Included"]
                  }
                  required={
                    productExcludeType !== EXCLUSION_TYPE["All Included"]
                  }
                />
              </Col>
            </>
          )}
          {isTaxCategoriesRequired && (
            <Col xs={12}>
              <FormSelect
                variant="underlined"
                name="productTaxCategoryId"
                label="Tax Category"
                placeholder="Select a tax category"
                formItemStyles={{ marginBottom: 0 }}
                initialValue={[]}
                options={taxCategories}
                // isOptionDisabled={(it) => !it.isActive}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase?.()
                    .includes?.(input.toLowerCase());
                }}
                renderLabel="taxCategoryName"
                renderValue="taxCategoryId"
                required
              />
            </Col>
          )}
          {(isVoucher ||
            isPromoGiftCard ||
            programType === PROGRAM_TYPE.Program ||
            programType === PROGRAM_TYPE.Membership) && (
            <>
              <Col xs={24} md={12}>
                <FormSelect
                  variant="underlined"
                  name="facilityExcludeType"
                  label="Facility Exclusion Type"
                  placeholder="Select exclusion type"
                  formItemStyles={{ marginBottom: 0 }}
                  options={exclusionTypes}
                  // isOptionDisabled={(it) => !it.isActive}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  renderLabel="label"
                  renderValue="value"
                  required
                />
              </Col>
              <Col xs={24} sm={12}>
                <FormSelect
                  mode={"multiple"}
                  feedBack={false}
                  variant="underlined"
                  name="facilityIds"
                  loading={isFetchingFacilities}
                  label="Facilities"
                  renderValue="facilityId"
                  placeholder={"Select Facilities"}
                  renderLabel="facilityName"
                  formItemStyles={{ marginBottom: 0 }}
                  // formItemStyles={{ marginBottom: 12 }}
                  options={allFacilities?.filter((it) => it?.isActive)}
                  optionLabelProp="label"
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  disabled={
                    facilityExcludeType === EXCLUSION_TYPE["All Included"]
                  }
                  required={
                    facilityExcludeType !== EXCLUSION_TYPE["All Included"]
                  }
                  // styles={{ background: '#ddd' }}
                />
              </Col>
            </>
          )}
          {isVoucher && (
            <>
              <Col xs={24} sm={12}>
                <FormSelect
                  required={true}
                  mode={"multiple"}
                  feedBack={false}
                  variant="underlined"
                  name="applications"
                  label="Who Can Apply"
                  renderValue="value"
                  placeholder={"Select who can apply"}
                  renderLabel="label"
                  formItemStyles={{ marginBottom: 0 }}
                  // formItemStyles={{ marginBottom: 12 }}
                  options={[
                    { label: "Guest", value: 1 },
                    { label: "Back Office", value: 2 },
                  ]}
                  // optionLabelProp="label"

                  // styles={{ background: '#ddd' }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <FormInputNumber
                  // required
                  variant="underlined"
                  name="redemptionLimit"
                  label="Redemption Limit"
                  placeholder="Redemption Limit"
                  min={0}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
            </>
          )}
          {programType === PROGRAM_TYPE.Program && (
            <Col xs={24} sm={12}>
              <FormInputNumber
                required
                variant="underlined"
                name="expirationInterval"
                label="Expiration Timeline (in days)"
                placeholder="Number of days"
                min={1}
                // initialValue={0}
                formItemStyles={{
                  margin: 0,
                }}
                rules={[]}
              />
            </Col>
          )}
        </Row>

        {!isGiftcardOrReferral && (
          <>
            <Row
              gutter={[24, 24]}
              style={{
                marginTop: 12,
              }}
            >
              <Col xs={24} md={12}>
                <FormDatePicker
                  form={formStep1}
                  // disabled={isAppointmentFormDisabled}
                  name="fromDate"
                  label="Start Date"
                  variant="underlined"
                  formItemStyles={{ marginBottom: 0 }}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  required
                />
              </Col>
              <Col xs={24} md={12}>
                <FormDatePicker
                  validateFirst
                  form={formStep1}
                  // disabled={isAppointmentFormDisabled}
                  name="tillDate"
                  label="End Date"
                  variant="underlined"
                  formItemStyles={{ marginBottom: 0 }}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  rules={[
                    {
                      required: false,
                      message: "Required",
                    },
                    {
                      message: "Start and End Date cannot be same",
                      validator: (_, value) => {
                        const startDate = formStep1.getFieldValue("fromDate");
                        const endDate = formStep1.getFieldValue("tillDate");

                        if (!endDate) {
                        } else if (
                          startDate?.format?.("DD-MM-YYYY") ===
                          endDate?.format?.("DD-MM-YYYY")
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                />
              </Col>
              {/* <Col xs={24} md={12}>
                <FormSelect
                  required
                  variant="underlined"
                  name={"redemptionLimit"}
                  renderLabel="label"
                  renderValue={"value"}
                  formItemStyles={{
                    marginBottom: 0,
                  }}
                  // options={facilities ?? []}
                  options={[
                    { label: "Once", value: 1 },
                    { label: "Twice", value: 2 },
                    { label: "Thrice", value: 3 },
                    { label: "Ten", value: 10 },
                  ]}
                  label={"How many times can this be utilized?"}
                  placeholder={"Select"}
                  emptyMessage="No options"
                  onChange={(value) => {
                    // dispatch(setTimer(moment().add(15, "minutes")));
                  }}
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                />
              </Col> */}
            </Row>
          </>
        )}

        <FormHiddenSubmitButton submitRef={step1SubmitRef} />
      </Form>
      {!isGiftcardOrReferral && !isVoucher && (
        <Form
          style={{
            height: !isLastStep ? 0 : "auto",
            overflowY: !isLastStep ? "hidden" : "auto",
            overflowX: "hidden",
          }}
          form={formStep2}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            console.log(errorFields);
            formStep2.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
          initialValues={{
            programApplications: [1, 2],
          }}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <ReviewProgram
            facilities={allFacilities}
            products={products}
            data={form1Data}
            style={{
              marginBottom: 16,
            }}
          />

          <Row gutter={[24, 25]}>
            <Col xs={24}>
              <Title
                variant={5}
                style={{
                  margin: 0,
                  fontSize: 16,
                  color: "#161F3A",
                  marginBottom: 12,
                }}
              >
                Description
              </Title>
              <FormInputArea
                name={"notes"}
                variant="underlined"
                formItemStyles={{ width: "100%", margin: 0 }}
              />
            </Col>
            <Col xs={24} md={12}>
              <FormCheckboxGroup
                hidden={true}
                initialValue={[1, 2]}
                className="contradictionsChecboxes"
                name="programApplications"
                label="Where can be used?"
                required={true}
                options={[
                  { label: "Guest", value: 1 },
                  { label: "Back Office", value: 2 },
                ]}
                formItemStyles={{ marginBottom: 12 }}
              />
            </Col>
            <Col xs={24} md={12}>
              <FormRadioGroup
                hidden={true}
                initialValue={true}
                required
                label="Status"
                className="boldRadio"
                name="isActive"
                //   label="How many days a week do you exercise?"
                options={[
                  { label: "Active", value: true },
                  {
                    label: "Inactive",
                    value: false,
                  },
                ]}
              />
            </Col>
          </Row>
          <FormHiddenSubmitButton submitRef={step2SubmitRef} />
        </Form>
      )}
    </Modal>
  );
}

ProgramCreateModal.propTypes = {};

export default ProgramCreateModal;
